import React from "react";
const placeholder_img = "/images/placeholder.png";

function EditCharacterModal({
  modalTitle,
  callbackSaveFunction,
  callbackSaveInEntitiesFunction,
  callbackCreateNewFunction,
  callbackCancelFunction,
  player,
  choosenEntity = undefined,
  isLoading = false,
}) {
  let entity = choosenEntity ?? player.entities[0];

  function base64(e) {
    let file = e.target.files[0];
    let reader = new FileReader();
    // Try to read the file image
    try {
      reader.readAsDataURL(file);
    } catch (error) {
      // If something goes bad, don't do anything
    }
    reader.onload = function () {
      // Set the image to the one that was read only if it is a data:image
      if (reader.result.toString().includes("data:image")) {
        let entity_image = reader.result;
        document.getElementById("character-image").src = entity_image;
      }
    };
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div
        className="flex items-center justify-center min-h-screen"
        onClick={(e) => e.stopPropagation()}
      >
        {isLoading ? (
          <img src="images/loading.gif" alt="loading gif" />
        ) : (
          <div className="bg-modal text-modal p-6 rounded shadow-lg relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="black"
              className="w-6 h-6 absolute top-2 right-2 text-modal cursor-pointer"
              onClick={callbackCancelFunction}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <h3 className="text-lg font-semibold text-gray-900 mb-4">
              {modalTitle}
            </h3>
            <main className="max-h-[50vh] max-w-[70vw] grid grid-cols-2">
              <div className="w-100 grid place-items-center">
                <img
                  className="w-auto hover:opacity-60 mx-10 max-h-[50vh]"
                  id="character-image"
                  src={entity?.image || placeholder_img}
                  alt="characterImage"
                  onClick={(e) => {
                    e.stopPropagation();
                    document.getElementById("character-image-input").click();
                  }}
                />
                <input
                  type="file"
                  id="character-image-input"
                  accept="image/*"
                  onChange={base64}
                ></input>
              </div>
              <div className="grid grid-cols-1 max-h-[50vh] overflow-auto">
                <span className="my-3 grid grid-cols-2 place-items-center">
                  <label htmlFor="input-name">Name</label>
                  <input
                    id="input-name"
                    className="text-black"
                    type="text"
                    defaultValue={entity?.name}
                  />
                </span>
                <span className="my-3 grid grid-cols-2 place-items-center">
                  <label htmlFor="input-description">Description</label>
                  <textarea
                    id="input-description"
                    className="text-black"
                    type="text"
                    defaultValue={entity?.description}
                  ></textarea>
                </span>
                <span className="my-3 grid grid-cols-2 place-items-center">
                  <label htmlFor="input-HP">HP</label>
                  <input
                    id="input-HP"
                    className="text-black"
                    type="number"
                    defaultValue={
                      entity?.meta.find((m) => m.key.toUpperCase() === "HP")
                        ?.value
                    }
                  />
                </span>
                <span className="my-3 grid grid-cols-2 place-items-center">
                  <label htmlFor="input-INITIATIVE">Initiative</label>
                  <input
                    id="input-INITIATIVE"
                    className="text-black"
                    type="number"
                    defaultValue={
                      entity?.meta.find(
                        (m) => m.key.toUpperCase() === "INITIATIVE"
                      )?.value
                    }
                  />
                </span>
                <span className="my-3 grid grid-cols-2 place-items-center">
                  <label htmlFor="input-MOVEMENT">Movement</label>
                  <input
                    id="input-MOVEMENT"
                    className="text-black"
                    type="number"
                    defaultValue={
                      entity?.meta.find(
                        (m) => m.key.toUpperCase() === "MOVEMENT"
                      )?.value
                    }
                  />
                </span>
              </div>
            </main>
            <hr className="my-4" />
            <div className="flex justify-end">
              {entity ? (
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded shadow-sm hover:bg-green-600 mr-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    entity.name = document.getElementById("input-name")?.value;

                    entity.description =
                      document.getElementById("input-description")?.value;

                    entity.image =
                      document.getElementById("character-image")?.src;

                    entity.meta.find(
                      (m) => m.key.toUpperCase() === "HP"
                    ).value = document.getElementById("input-HP")?.value;

                    entity.meta.find(
                      (m) => m.key.toUpperCase() === "INITIATIVE"
                    ).value =
                      document.getElementById("input-INITIATIVE")?.value;

                    entity.meta.find(
                      (m) => m.key.toUpperCase() === "MOVEMENT"
                    ).value = document.getElementById("input-MOVEMENT")?.value;
                    callbackSaveFunction(player, entity);
                  }}
                >
                  Save updates
                </button>
              ) : (
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded shadow-sm hover:bg-green-600 mr-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    let newEntity = {
                      name: "",
                      description: "",
                      image: "",
                      meta: [
                        { key: "HP", value: "" },
                        { key: "INITIATIVE", value: "" },
                        { key: "MOVEMENT", value: "" },
                      ],
                    };
                    newEntity.name =
                      document.getElementById("input-name").value;
                    newEntity.description =
                      document.getElementById("input-description").value;
                    newEntity.image =
                      document.getElementById("character-image").src;
                    newEntity.meta.find(
                      (m) => m.key.toUpperCase() === "HP"
                    ).value = document.getElementById("input-HP").value;
                    newEntity.meta.find(
                      (m) => m.key.toUpperCase() === "INITIATIVE"
                    ).value = document.getElementById("input-INITIATIVE").value;
                    newEntity.meta.find(
                      (m) => m.key.toUpperCase() === "MOVEMENT"
                    ).value = document.getElementById("input-MOVEMENT").value;
                    callbackCreateNewFunction(player, newEntity);
                  }}
                  disabled={isLoading}
                >
                  Create and Add
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default EditCharacterModal;
