import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import PlayersList from "../../components/PlayersList/PlayersList";
import { socket } from "../../socket";

function Lobby() {
  const navigate = useNavigate();
  const TABLE_ID = localStorage.getItem("table_id");

  // set page title
  document.title = "TTRPGTA - Lobby";

  // fetch from backend the tables
  const [table, setTable] = useState({});
  const [copyButtonText, setCopyButtonText] = useState("Copy ID");
  const [isPrepared, setPrepared] = useState(false);
  const [canStart, setCanStart] = useState(false);
  const [dm, setDm] = useState({});

  const [playerEntities, setPlayerEntities] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));

  function CopyId() {
    let copyText = document.getElementById("real_id");
    try {
      navigator.clipboard.writeText(copyText.innerHTML); // Copy the text inside the text field
    } catch (error) {}
    toast.remove();
    toast.success("Copied lobby ID!");
    setCopyButtonText("Copied!");
    setTimeout(() => {
      setCopyButtonText("Copy ID");
    }, 5000);
  }
  // set the player entities
  useEffect(() => {
    let player_entities = table?.team?.find((player) => {
      return player.user_id === JSON.parse(localStorage.getItem("user"))._id;
    })?.entities;

    setPlayerEntities(player_entities);
  }, [table]);

  // set the dm
  useEffect(() => {
    fetch("api/table/" + TABLE_ID + "/team/full", {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDm(data.team[0]);
      });
  }, [table]);

  useEffect(() => {
    if (!TABLE_ID) {
      navigate("/lobbies");
      return;
    }

    fetch("/api/table/" + TABLE_ID, {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTable(data);
      })
      .catch((err) => {
        if (err.message === "TNV") {
          // If the token is not valid, redirect to the login page
          navigate("/login");
        }
      });
  }, [navigate, TABLE_ID]);

  useEffect(() => {
    // Emit the socket signal
    socket.emit("triggerUserIsReady", {
      user_id: user._id,
      table_id: TABLE_ID,
      is_ready: isPrepared,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrepared, TABLE_ID]);

  useEffect(() => {
    let player_entities = table?.team?.find((player) => {
      return player.user_id === JSON.parse(localStorage.getItem("user"))._id;
    })?.entities;

    setPlayerEntities(player_entities);
  }, [table]);

  function ready() {
    // Set the isPrepared
    setPrepared(!isPrepared);
  }

  return (
    <div className="main-Container w-full flex-col relative py-10">
      {/* Copy Div + Button */}
      <div className="grid grid-cols-2 gap-4 absolute right-5 top-5">
        <span className="self-center">TABLE ID: </span>
        <span className="self-center" id="real_id">
          {table.table_code}
        </span>
        <button
          className="main-button col-span-2"
          id="copy_button"
          onClick={CopyId}
        >
          {copyButtonText}
        </button>
      </div>
      <Toaster />
      <h1 className="my-10 font-extrabold text-3xl">{table.name}</h1>

      <h2 className="center-text my-5 text-xl">
        The DM for this campaign is{" "}
        <span className="font-bold">{dm.username}</span>
      </h2>

      <PlayersList
        isPrepared={isPrepared}
        canStart={canStart}
        setCanStart={setCanStart}
        table={table}
        setTable={setTable}
      />

      {table.dm_id === user._id ? (
        <button
          className={canStart ? "main-button" : "main-button-disabled"}
          id="start_button"
          disabled={!canStart}
          onClick={() => {
            socket.emit("startGame", {
              table_id: TABLE_ID,
              user_id: user._id,
            });
            navigate("/grid");
          }}
        >
          Start Table
        </button>
      ) : (
        <>
          <button
            className={
              !playerEntities || playerEntities.length === 0
                ? "main-button-disabled"
                : "main-button"
            }
            id="ready-button"
            onClick={ready}
            disabled={!playerEntities || playerEntities.length === 0}
          >
            {!isPrepared ? "READY" : "NOT READY"}
          </button>
          {(!playerEntities || playerEntities.length === 0) && (
            <Tooltip anchorSelect={`#ready-button`} place="bottom" clickable>
              <p>You cant be ready without a character...</p>
              <p>Add a character to continue!</p>
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
}

export default Lobby;
