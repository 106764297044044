import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import EntityList from "../../components/EntityList/EntityList";
import CharacterSheetModal from "../../components/Modals/CharacterSheetModal";

function EntityManager() {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [sentToDB, setSentToDB] = useState(false);
  const [userEntitiesId, setUserEntitiesId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // set page title
  document.title = "TTRPGTA - Entity Manager";

  return (
    <div className="App">
      <div className="main-Container justify-center">
        <div id="entityListContainer" className=" max-h-screen overflow-auto">
          <EntityList
            setIsEditing={setIsEditing}
            setSelectedEntity={setSelectedEntity}
            sentToDB={sentToDB}
            setSentToDB={setSentToDB}
            setUserEntitiesId={setUserEntitiesId}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            entityOwner={JSON.parse(localStorage.getItem("user"))._id}
          />
        </div>
        <div id="characterSheetContainer">
          {isEditing && (
            <CharacterSheetModal
              key={uuidv4()}
              entity={selectedEntity}
              setIsEditing={setIsEditing}
              sentToDB={sentToDB}
              setSentToDB={setSentToDB}
              userEntitiesId={userEntitiesId}
              isEditing={isEditing}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              table_id={undefined}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EntityManager;
