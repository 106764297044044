const loadingGif = "./images/d20.gif";

function loadingScreenModal(conditionToShow) {
  if (conditionToShow) {
    return (
      <div className="loadingD20">
        <img src={loadingGif} alt="loading" />
        <p>Waiting for the DM to start the session...</p>
      </div>
    );
  }
  return null;
}

export default loadingScreenModal;
