import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { socket } from "../../socket";
import ActionConfirmationModal from "../Modals/ActionConfirmationModal";
import ChooseEntitiesModal from "../Modals/ChooseEntitiesModal";
import EditCharacterModal from "../Modals/EditCharacterModal";

let TABLE_ID = localStorage.getItem("table_id");

function PlayersList({ isPrepared, setCanStart, table, setTable }) {
  const navigate = useNavigate();

  const [players, setPlayers] = useState([]);
  const [readyStatuses, setReadyStatuses] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));

  const [isOpenImport, setIsOpenImport] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const [kickConfirmModalOpen, setKickConfirmModalOpen] = useState(false);
  const [kickedPlayer, setKickedPlayer] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  localStorage.removeItem("entity_info");

  function getTeam(fromModal = false) {
    TABLE_ID = localStorage.getItem("table_id");
    if (fromModal) {
      socket.emit("changedEntityInLobby", {
        table_id: TABLE_ID,
        user_id: user._id,
      });
    }
    if (!TABLE_ID) {
      navigate("/lobbies");
      return;
    }

    fetch("api/table/" + TABLE_ID + "/team/full", {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPlayers(data.team);

        // Deprecated but works :) (i'm sorry)
        // If the user refreshes the page, emit the enteredLobby signal
        // to get the ready status of the other users
        if (performance.navigation.type === 1) {
          socket.emit("enteredLobby", {
            user_id: user._id,
            table_id: TABLE_ID,
            is_dm: user._id === data.team[0]?.user_id,
          });
        }
        if (fromModal) {
          setTable({
            ...table,
            team: data.team,
          });
        }
      })
      .catch((err) => {
        if (err.message === "TNV") {
          // If the token is not valid, redirect to the login page
          navigate("/login");
        }
      });
  }

  useEffect(() => {
    getTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  /**
   * Set the canStart to true if all the users are ready
   * This method is called when the players array is updated
   */
  useEffect(() => {
    if (players.length === 0) return;
    if (players.length === Object.keys(readyStatuses).length) {
      // If all the users are ready, set the canStart to true
      let allReady = true;
      for (let key in readyStatuses) {
        if (!readyStatuses[key].ready) {
          allReady = false;
          break;
        }
      }
      setCanStart(allReady);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [players]);

  useEffect(() => {
    // Set the socket listeners
    socketListeners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [players, readyStatuses]);

  useEffect(() => {
    // Set the current user ready status

    readyStatuses[user._id] = { ...readyStatuses[user._id], ready: isPrepared };

    setReadyStatuses({ ...readyStatuses });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrepared]);

  function socketListeners() {
    if (socket.hasListeners("triggerUserIsReady")) {
      socket.off("triggerUserIsReady");
    }
    if (socket.hasListeners("newUser")) {
      socket.off("newUser");
    }
    if (socket.hasListeners("userLeft")) {
      socket.off("userLeft");
    }
    if (socket.hasListeners("usersInLobby")) {
      socket.off("usersInLobby");
    }
    if (socket.hasListeners("startGame")) {
      socket.off("startGame");
    }
    if (socket.hasListeners("changedEntityInLobby")) {
      socket.off("changedEntityInLobby");
    }
    if (socket.hasListeners("playerKicked")) {
      socket.off("playerKicked");
    }

    socket.on("changedEntityInLobby", (data) => {
      getTeam();
    });

    socket.on("triggerUserIsReady", (data) => {
      readyStatuses[data.user_id] = {
        ...readyStatuses[data.user_id],
        ready: data.is_ready,
      };
      setReadyStatuses({ ...readyStatuses });

      if (players.length !== Object.keys(readyStatuses).length) return;

      // If all the users are ready, set the canStart to true
      let allReady = true;
      for (let key in readyStatuses) {
        if (!readyStatuses[key].ready) {
          allReady = false;
          break;
        }
      }
      setCanStart(allReady);
    });

    socket.on("usersInLobby", (data) => {
      setReadyStatuses(data);
      let len = Object.keys(data).length;

      if (len === 0) return;
      if (len === players.length) {
        let allReady = true;
        for (let key in data) {
          if (!data[key].ready) {
            allReady = false;
            break;
          }
        }
        setCanStart(allReady);
      }
    });

    socket.on("newUser", () => {
      getTeam();
    });

    socket.on("userLeft", () => {
      getTeam();
    });

    socket.on("startGame", () => {
      navigate("/grid");
    });

    socket.on("playerKicked", (player) => {
      if (
        player.user_id === user._id &&
        window.location.pathname === "/lobby"
      ) {
        localStorage.setItem("kicked", table.name);
        navigate("/lobbies");
      } else {
        getTeam();
      }
    });
  }

  function kickPlayer(player) {
    setKickedPlayer(player);
    setKickConfirmModalOpen(true);
  }

  return (
    <div className="my-2">
      <Toaster />
      <table className="lobbyTable">
        <thead className="text-lg">
          <tr>
            <th></th>
            <th>Username</th>
            <th>Entity Name</th>
            <th>HP</th>
            <th>Initiative</th>
            <th>Description</th>
            <th>Actions</th>
            <th>Ready</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-dashed">
          {players.map((player, index) =>
            index === 0 ? null : (
              <tr key={uuidv4()}>
                <td className="px-6">
                  {player.entities[0]?.image && (
                    <img
                      src={player.entities[0]?.image}
                      alt="Entity_IMG"
                      className="w-16 h-16 rounded-full p-1"
                    />
                  )}
                </td>
                <td className="px-6">{player.username}</td>
                <td className="px-6">
                  {player?.entities[0]?.name || "MISSING"}
                </td>
                <td className="px-6">
                  {player?.entities[0]?.meta.find(
                    (item) => item.key.toUpperCase() === "HP"
                  )?.value || "MISSING"}
                </td>
                <td className="px-6">
                  {player?.entities[0]?.meta.find(
                    (item) => item.key.toUpperCase() === "INITIATIVE"
                  )?.value || "MISSING"}
                </td>
                <td className="px-6">
                  {player.entities[0]?.description || "MISSING"}
                </td>
                <td className="px-6 flex justify-center">
                  {player.user_id === user._id ? (
                    <>
                      <button
                        className="action-button"
                        onClick={() => {
                          localStorage.setItem(
                            "entity_info",
                            JSON.stringify({
                              table_id: TABLE_ID,
                              player_id: player.user_id,
                              entity_id: player.entities[0]?._id,
                            })
                          );
                          setIsOpenImport(true);
                          setSelectedPlayer(player);
                        }}
                      >
                        {player.entities[0] === undefined ? "Add" : "Replace"}
                      </button>
                      <button
                        className="action-button"
                        onClick={() => {
                          setIsOpenEdit(true);
                          setSelectedPlayer(player);
                        }}
                      >
                        {player.entities[0] ? "Edit" : "Create new"}
                      </button>
                    </>
                  ) : null}

                  {players[0].user_id === user._id ? (
                    <button
                      className="action-button"
                      onClick={() => kickPlayer(player)}
                    >
                      Kick
                    </button>
                  ) : null}
                </td>
                <td className="px-6">
                  {readyStatuses[player.user_id]?.ready ?? false ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="3"
                      stroke="green"
                      className=" w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="3"
                      stroke="red"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
      {/* ----- MODAL ----- */}
      {isOpenImport && (
        <ChooseEntitiesModal
          modalTitle="Choose your entity..."
          modalDescription="(note that if you choose a new one, the old one will be deleted)"
          player={selectedPlayer}
          chooseOnlyOne={true}
          callbackCancelFunction={() => setIsOpenImport(false)}
          callbackImportFunction={(choosenEntities, player) => {
            if (choosenEntities.length === 0) {
              toast.remove();
              toast.error("Please select an entity");
            } else {
              setIsLoading(true);
            }
            choosenEntities.forEach((choosenEntity) => {
              // add the entity to the player of specified table
              fetch(
                "api/table/" +
                  localStorage.getItem("table_id") +
                  "/team/" +
                  user?._id +
                  "/entity" +
                  (player.entities[0] ? "/" + player.entities[0]._id : ""),
                {
                  method: player.entities[0] ? "PUT" : "POST",
                  body: JSON.stringify({
                    name: choosenEntity.name || "-",
                    description: choosenEntity.description,
                    image: choosenEntity.image || "/images/placeholder.png",
                    meta: choosenEntity.meta,
                  }),
                }
              )
                .then((res) => res.json())
                .then((data) => {
                  getTeam(true);
                  setIsOpenImport(false);
                  setIsLoading(false);
                })
                .catch((err) => {
                  setIsLoading(false);
                  if (err.message === "TNV") {
                    // If the token is not valid, redirect to the login page
                    navigate("/login");
                  }
                });
            });
          }}
          isLoading={isLoading}
        />
      )}
      {kickConfirmModalOpen && (
        <ActionConfirmationModal
          callbackActionFunction={() => {
            setIsLoading(true);
            fetch("api/table/" + table._id + "/team/" + kickedPlayer.user_id, {
              method: "DELETE",
            })
              .then((res) => res.json())
              .then((data) => {
                socket.emit("kickPlayer", {
                  player: kickedPlayer,
                  table_id: table._id,
                });
                getTeam();
                setKickedPlayer("");
                setKickConfirmModalOpen(false);
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                if (err.message === "TNV") {
                  // If the token is not valid, redirect to the login page
                  navigate("/login");
                }
              });
          }}
          callbackCancelFunction={() => {
            setKickConfirmModalOpen(false);
            setKickedPlayer("");
          }}
          modalTitle={"Kick Player"}
          modalDescription={
            "Are you sure you want to kick " + kickedPlayer.username + "?"
          }
          modalAction={"Kick"}
          isLoading={isLoading}
        />
      )}
      {isOpenEdit && (
        <EditCharacterModal
          modalTitle="Edit your character"
          player={selectedPlayer}
          callbackSaveFunction={(selectedPlayer, entity) => {
            setIsLoading(true);
            fetch(
              "api/table/" +
                TABLE_ID +
                "/team/" +
                selectedPlayer.user_id +
                "/entity/" +
                entity._id,
              {
                method: "PUT",
                body: JSON.stringify(entity),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                getTeam(true);
                setIsOpenImport(false);
                setIsOpenEdit(false);
                setIsLoading(false);
              })
              .catch((error) => {
                setIsLoading(false);
                // if error is TNV (token not valid), redirect to login page using navigate
                if (error.message === "TNV") {
                  navigate("/login");
                }
              });
          }}
          callbackSaveInEntitiesFunction={(selectedPlayer, entity) => {
            if (entity.name.trim() === "") {
              const randomNumber = (
                "0000" + Math.floor(Math.random() * 10000)
              ).slice(-4);
              entity.name = `Entity${randomNumber}`;
            }
            setIsOpenEdit(false);
            fetch(
              "api/table/" +
                JSON.parse(localStorage.getItem("user")).entities_id +
                "/team/" +
                selectedPlayer.user_id +
                "/entity",
              { method: "POST", body: JSON.stringify(entity) }
            )
              .then((res) => res.json())
              .then((data) => {
                let newEntity =
                  data.team[0].entities[data.team[0].entities.length - 1];
                fetch(
                  "api/table/" +
                    localStorage.getItem("table_id") +
                    "/team/" +
                    selectedPlayer.user_id +
                    "/entity",
                  {
                    method: "POST",
                    body: JSON.stringify(newEntity),
                  }
                )
                  .then((res) => res.json())
                  .then(() => getTeam(true));
              });
          }}
          callbackCreateNewFunction={(selectedPlayer, entity) => {
            setIsLoading(true);
            if (entity.name.trim() === "") {
              const randomNumber = (
                "0000" + Math.floor(Math.random() * 10000)
              ).slice(-4);
              entity.name = `Entity${randomNumber}`;
            }
            fetch(
              "api/table/" +
                JSON.parse(localStorage.getItem("user")).entities_id +
                "/team/" +
                selectedPlayer.user_id +
                "/entity",
              { method: "POST", body: JSON.stringify(entity) }
            )
              .then((res) => res.json())
              .then((data) => {
                setIsLoading(false);
                setIsOpenEdit(false);
                let newEntity =
                  data.team[0].entities[data.team[0].entities.length - 1];
                fetch(
                  "api/table/" +
                    localStorage.getItem("table_id") +
                    "/team/" +
                    selectedPlayer.user_id +
                    "/entity",
                  {
                    method: "POST",
                    body: JSON.stringify(newEntity),
                  }
                )
                  .then((res) => res.json())
                  .then(() => getTeam(true));
              });
          }}
          callbackCancelFunction={() => setIsOpenEdit(false)}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default PlayersList;
