// Navbar component
import { Link, useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../../middleware/IsAuth/IsAuth";

function Navbar() {
  let navigate = useNavigate();

  function LogOut() {
    // Calling the clear also before to be able to render the navbar correctly
    clearLocalStorage();
    // In the login page, we clear the local storage
    navigate("/login");
  }

  function isLoggedIn() {
    const userData = localStorage.getItem("user");
    if (userData !== null) {
      return (
        <>
          <li className="elementNavbar">
            <Link to="/userManager" className="clickableLink">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="black"
                className="w-6 h-6 px-px place-items-center mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                />
              </svg>
              Account
            </Link>
          </li>
          <li className="elementNavbar">
            <span
              className="cursor-pointer flex items-start w-full text-start text-zinc-950 font-bold"
              tabIndex={0}
              onClick={LogOut}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                className="h-6 w-6 px-px place-items-center mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25a.75.75 0 01.75.75v9a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM6.166 5.106a.75.75 0 010 1.06 8.25 8.25 0 1011.668 0 .75.75 0 111.06-1.06c3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788a.75.75 0 011.06 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Log out
            </span>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li className="elementNavbar">
            <Link to="/login" className="clickableLink">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="black"
                className=" w-6 h-6 place-items-center mr-2"
              >
                <path d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
              </svg>
              Login
            </Link>
          </li>
          <li className="elementNavbar">
            <Link to="/register" className="clickableLink">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="black"
                className=" w-6 h-6 place-items-center mr-2"
              >
                <path d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
              </svg>
              Register
            </Link>
          </li>
        </>
      );
    }
  }

  return (
    <>
      {window.location.pathname !== "/grid" &&
      localStorage.getItem("from") !== "grid" ? (
        <nav className="flex bg-indigo-500 w-max min-h-screen ">
          <ul className="h-full m-0  ">
            <li className="elementNavbar">
              <Link to="/" className="clickableLink">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="black"
                  className=" w-6 h-6 place-items-center mr-2"
                >
                  <path d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                </svg>
                TTRPGTA
              </Link>
            </li>
            <li className="elementNavbar">
              <Link to="/lobbies" className="clickableLink">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="black"
                  className="w-6 h-6 place-items-center mr-2"
                >
                  <path d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                Campaigns
              </Link>
            </li>
            <li className="elementNavbar">
              <Link to="/entityManager" className="clickableLink">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="black"
                  className="w-6 h-6 place-items-center mr-2"
                >
                  <path d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                </svg>
                Entity <br /> Manager
              </Link>
            </li>
            {isLoggedIn()}
          </ul>
        </nav>
      ) : null}
    </>
  );
}

export default Navbar;
