import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import EntityCard from "../../components/EntityCard/EntityCard";

function EntityList({
  setSelectedEntity,
  setIsEditing,
  sentToDB,
  setUserEntitiesId,
  isLoading,
  entityOwner,
}) {
  const navigate = useNavigate();
  const [entities, setEntities] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    setUserEntitiesId(user.entities_id);

    fetch("api/user/" + user._id + "/entities")
      .then((response) => {
        if (!response.ok && response.status !== 304) {
          throw new Error("Server error");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setEntities(data);
        setSelectedEntity({}); // argue if must be possible to create multiple instance of the same entity
      })
      .catch((error) => {
        if (error.message === "TNV") {
          navigate("/login");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, sentToDB, setUserEntitiesId]);

  function createNewEntity() {
    setIsEditing(true);
    setSelectedEntity({});
  }

  return (
    <div className={`${isLoading ? "pointer-events-none" : ""}`}>
      <div className="flex items-center justify-center">
        <input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          className="text-white bg-gray-800 rounded-lg border-2 border-gray-700 shadow-lg main-button hover:bg-gray-700 mr-2 outline-none"
        />
        <button onClick={createNewEntity} className="main-button">
          Create new entity
        </button>
      </div>
      <div className="flex flex-wrap content-start mu">
        {entities
          .filter((entity) =>
            entity.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((entity, index) => (
            <EntityCard
              entity={{ ...entity, user_id: user._id }}
              entityIndex={index}
              key={uuidv4()}
              isEditable={true}
              managerView={true}
              setIsEditing={setIsEditing}
              setSelectedEntity={setSelectedEntity}
              entityOwner={entityOwner}
            />
          ))}
        {entities.filter((entity) =>
          entity.name.toLowerCase().includes(searchTerm.toLowerCase())
        ).length === 0 && <p className="text-center m-4">No entities found</p>}
      </div>
    </div>
  );
}

export default EntityList;
