import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import EditCharacterModal from "../Modals/EditCharacterModal";

const placeholder_img = "/images/placeholder.png";

const USER_ID = JSON.parse(localStorage.getItem("user"))?._id;
let TABLE_ID = localStorage.getItem("table_id");

function EntityCard({
  entity,
  entityIndex,
  isEditable,
  managerView,
  setIsEditing,
  setSelectedEntity,
  importView = undefined,
  entityOwner,
  selectedEntity,
  table,
  callbackRefetchTable,
  movingEntity,
}) {
  const [entityData, setEntityData] = useState(entity);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEntityData(entity);
  }, [entity]);

  useEffect(() => {
    TABLE_ID = localStorage.getItem("table_id");
  }, []);

  function handleEdit(entity) {
    if (
      setSelectedEntity &&
      (entityOwner === USER_ID || table?.dm_id === USER_ID)
    ) {
      // if we click on the same entity, we deselect it
      if (entity._id === selectedEntity?._id) {
        setSelectedEntity(null);
        return;
      }

      entity.user_id = entityOwner;
      setSelectedEntity(entity);
    }
    if (setIsEditing) {
      setIsEditing(true);
    }
  }

  if (!entityData) return null;
  return (
    <div
      id={`entity-anchor-${entityIndex}`}
      className={`${
        entity._id === selectedEntity?._id ||
        (entity._id === movingEntity?._id && selectedEntity === null)
          ? "selectedBorder"
          : ""
      } character-card w-[200px] max-w-[90%] h-fit cursor-pointer`}
      onClick={() => {
        handleEdit(entity);
      }}
    >
      <img
        alt="character-img"
        src={entityData?.image === "" ? placeholder_img : entityData.image}
        className="max-h-[150px] m-auto"
      />
      <span className="font-extrabold">{entityData.name}</span>
      {!managerView && !importView ? (
        <Tooltip
          anchorSelect={`#entity-anchor-${entityIndex}`}
          place="right"
          clickable
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className="grid grid-cols-2 gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <span>HP</span>
            <span>
              {entityData.meta?.find((item) => item.key.toUpperCase() === "HP")
                ?.value || "-"}
            </span>
            <span>INITIATIVE</span>
            <span>
              {entityData.meta?.find(
                (item) => item.key.toUpperCase() === "INITIATIVE"
              )?.value || "-"}
            </span>
            <span>MOVEMENT</span>
            <span>
              {entityData.meta?.find(
                (item) => item.key.toUpperCase() === "MOVEMENT"
              )?.value || "-"}
            </span>
          </div>
          {(isEditable && entityData?.user_id !== undefined) ||
          entityData?.user_id !== table?.dm_id ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <button
                className="main-buttons"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditModalOpen(true);
                }}
              >
                Edit
              </button>
            </div>
          ) : null}
        </Tooltip>
      ) : null}
      {managerView || importView ? (
        <div>
          <div>
            HP:{" "}
            {entityData.meta?.find((item) => item.key.toUpperCase() === "HP")
              ?.value || "-"}
          </div>
          <div>
            Initiative:{" "}
            {entityData.meta?.find(
              (item) => item.key.toUpperCase() === "INITIATIVE"
            )?.value || "-"}
          </div>
          <div>
            Movement:{" "}
            {entityData.meta?.find(
              (item) => item.key.toUpperCase() === "MOVEMENT"
            )?.value || "-"}
          </div>
        </div>
      ) : null}
      {isEditModalOpen ? (
        <EditCharacterModal
          modalTitle="Edit your character"
          player={table?.team.find((player) => player.user_id === entityOwner)}
          choosenEntity={table?.team
            .find((player) => player.user_id === USER_ID)
            .entities.find((playerEntity) => playerEntity._id === entity._id)}
          callbackSaveFunction={(selectedPlayer, selectecEntity) => {
            setIsLoading(true);
            fetch(
              "/api/table/" +
                TABLE_ID +
                "/team/" +
                entityOwner +
                "/entity/" +
                selectecEntity._id,
              {
                method: "PUT",
                body: JSON.stringify(selectecEntity),
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setEntityData(
                  data?.team
                    .find((player) => player.user_id === USER_ID)
                    .entities.find(
                      (entity) => entity._id === selectecEntity._id
                    )
                );
                setIsEditModalOpen(false);
                callbackRefetchTable();
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                if (err.message === "TNV") {
                  // If the token is not valid, redirect to the login page
                  navigate("/login");
                }
              });
          }}
          callbackSaveInEntitiesFunction={(selectedPlayer, entity) => {
            setIsLoading(true);
            fetch(
              "api/table/" +
                JSON.parse(localStorage.getItem("user")).entities_id +
                "/team/" +
                entityOwner +
                "/entity/",
              {
                method: "POST",
                body: JSON.stringify(entity),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                // TODO: add a toast to confirm the addition
                setIsLoading(false);
              })
              .catch((error) => {
                setIsLoading(false);
                // if error is TNV (token not valid), redirect to login page using navigate
                if (error.message === "TNV") {
                  navigate("/login");
                }
              });
          }}
          callbackCancelFunction={() => setIsEditModalOpen(false)}
        />
      ) : null}
    </div>
  );
}

export default EntityCard;
