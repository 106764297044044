import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import EntityCard from "../EntityCard/EntityCard";

function ChooseEntitiesModal({
  modalTitle,
  modalDescription = null,
  player,
  callbackImportFunction,
  callbackCancelFunction,
  chooseOnlyOne = false,
  isLoading = false,
}) {
  const navigate = useNavigate();
  const [entities, setEntities] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));
  let choosenEntities = [];

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    fetch("api/user/" + user._id + "/entities")
      .then((response) => {
        console.log(response.status);
        if (!response.ok && response.status !== 304) {
          throw new Error("Server error");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setEntities(data);
      })
      .catch((error) => {
        if (error.message === "TNV") {
          navigate("/login");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        {isLoading ? (
          <img src="images/loading.gif" alt="loading gif" />
        ) : (
          <div className="bg-white p-6 rounded shadow-lg">
            <h3 className="text-lg font-semibold text-gray-900">
              {modalTitle}
            </h3>
            <p className="mb-4 text-gray-900">{modalDescription}</p>
            <main className="flex flex-wrap content-start max-h-[50vh] max-w-[50vw] overflow-auto">
              {entities.map((entity, index) => (
                <span
                  key={uuidv4()}
                  id={`card-${index}`}
                  onClick={() => {
                    if (chooseOnlyOne) {
                      choosenEntities = [entity];
                      document
                        .querySelectorAll(".selected-card")
                        .forEach((e) => {
                          e.classList.remove("selected-card");
                        });
                      document
                        .getElementById(`card-${index}`)
                        .classList.toggle("selected-card");
                    } else {
                      if (choosenEntities.includes(entity)) {
                        choosenEntities = choosenEntities.filter(
                          (e) => e !== entity
                        );
                      } else {
                        choosenEntities.push(entity);
                      }
                      document
                        .getElementById(`card-${index}`)
                        .classList.toggle("selected-card");
                    }
                  }}
                >
                  <EntityCard
                    entity={entity}
                    entityIndex={index}
                    key={uuidv4()}
                    isEditable={false}
                    managerView={false}
                    setIsEditing={false}
                    setSelectedEntity={undefined}
                    importView={true}
                  />
                </span>
              ))}
            </main>
            <div className="flex justify-end">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded shadow-sm hover:bg-green-600 mr-2"
                onClick={() => {
                  callbackImportFunction(choosenEntities, player);
                }}
              >
                Choose characters
              </button>
              <button
                className="bg-gray-200 text-gray-800 px-4 py-2 rounded shadow-sm hover:bg-gray-300"
                onClick={callbackCancelFunction}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ChooseEntitiesModal;
