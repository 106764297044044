import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ActionConfirmationModal from "./ActionConfirmationModal";

const placeholder_img = "/images/placeholder.png";

let entity_image = placeholder_img;

function checkContent(input) {
  if (input.value.toString().trim() === "") {
    input.style.border = "4px solid white";
  } else {
    input.style.border = "4px solid orange";
  }
}

function base64(e) {
  let file = e.target.files[0];
  let reader = new FileReader();
  // Try to read the file image
  try {
    reader.readAsDataURL(file);
  } catch (error) {
    // If something goes bad, don't do anything
  }
  reader.onload = function () {
    // Set the image to the one that was read only if it is a data:image
    if (reader.result.toString().includes("data:image")) {
      entity_image = reader.result;
      document.getElementById("character-image").src = entity_image;
    }
  };
  document.getElementById("upload-text").classList.remove("opacity-30");
  document.getElementById("upload-text").classList.add("opacity-0");
}

function saveCharacterInDB(
  e,
  navigate,
  ENTITY_ID,
  TABLE_ID,
  USER_ID,
  sentToDB,
  setSentToDB,
  setIsEditing,
  setIsLoading,
  entity
) {
  e.preventDefault();
  setIsLoading(true);
  let entity_name = e.target.elements.cName.value;
  let entity_hp = e.target.elements.hp.value;
  let entity_initiative = e.target.elements.initiative.value;
  let entity_movement = e.target.elements.movement.value;
  let entity_description = e.target.elements.description.value;

  if (entity_name.trim() === "") {
    const randomNumber = ("0000" + Math.floor(Math.random() * 10000)).slice(-4);
    entity_name = `Entity${randomNumber}`;
  }

  let meta = [
    {
      key: "HP",
      value: entity_hp || "",
    },
    {
      key: "INITIATIVE",
      value: entity_initiative || "",
    },
    {
      key: "MOVEMENT",
      value: entity_movement || "",
    },
  ];

  fetch(
    "api/table/" +
      TABLE_ID +
      "/team/" +
      USER_ID +
      "/entity" +
      (ENTITY_ID ? "/" + ENTITY_ID : ""),
    {
      method: ENTITY_ID ? "PUT" : "POST",
      body: JSON.stringify({
        name: entity_name,
        image:
          entity_image !== placeholder_img
            ? entity_image
            : entity?.image || entity_image,
        description: entity_description,
        meta,
      }),
    }
  )
    .then((response) => {
      if (!response.ok && response.status !== 304) {
        // give an error with the message that is in the response
        throw new Error("Table, user or entity not found");
      } else {
        setSentToDB(!sentToDB);
        setIsEditing(false);
      }
      entity_image = placeholder_img;
    })
    .then((data) => {
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      // if error is TNV (token not valid), redirect to login page using navigate
      if (error.message === "TNV") {
        navigate("/login");
      }
    });
}

function deleteEntityFromDB(
  e,
  navigate,
  ENTITY_ID,
  TABLE_ID,
  USER_ID,
  sentToDB,
  setSentToDB,
  setIsEditing,
  setIsLoading
) {
  e.preventDefault();
  setIsLoading(true);
  fetch("api/table/" + TABLE_ID + "/team/" + USER_ID + "/entity/" + ENTITY_ID, {
    method: "DELETE",
  })
    .then((response) => {
      if (!response.ok && response.status !== 304) {
        throw new Error("Table, user or entity not found");
      } else {
        setSentToDB(!sentToDB);
        setIsEditing(false);
      }
    })
    .then((data) => {
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      // if error is TNV (token not valid), redirect to login page using navigate
      if (error.message === "TNV") {
        navigate("/login");
      }
    });
}

function CharacterSheetModal({
  entity,
  setIsEditing,
  sentToDB,
  setSentToDB,
  userEntitiesId,
  isEditing,
  setIsLoading,
  isLoading,
  table_id,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  let user = localStorage.getItem("user");

  if (!user) {
    navigate("/login");
  }

  const USER_ID = JSON.parse(user)._id;
  const ENTITY_ID = entity?._id ?? "";
  const TABLE_ID = table_id || userEntitiesId;

  return (
    <div className="fixed inset-0 z-10 bg-black bg-opacity-50">
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          {isLoading ? (
            <img src="images/loading.gif" alt="loading gif" />
          ) : (
            <div className="cs-modal p-6 rounded-lg border-4 border-gray-700 shadow-lg">
              <div className="App">
                <header className="justify-center">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (TABLE_ID !== "" && USER_ID !== "") {
                        saveCharacterInDB(
                          e,
                          navigate,
                          ENTITY_ID,
                          TABLE_ID,
                          USER_ID,
                          sentToDB,
                          setSentToDB,
                          setIsEditing,
                          setIsLoading,
                          entity
                        );
                      }
                    }}
                  >
                    <div type="submit" className="characterSheetContainer">
                      <div className="flex justify-center items-center relative">
                        <span
                          id="upload-text"
                          className="absolute font-bold z-10 opacity-30"
                          style={{ pointerEvents: "none" }}
                        >
                          Upload an image
                        </span>
                        <img
                          className="mx-auto max-w-[150px] max-h-[150px] hover:opacity-30"
                          id="character-image"
                          src={
                            !entity?.image || entity?.image === ""
                              ? placeholder_img
                              : entity.image
                          }
                          alt="characterImage"
                          onMouseEnter={() => {
                            if (entity?.image) {
                              document
                                .getElementById("upload-text")
                                .classList.remove("hidden");
                              document
                                .getElementById("upload-text")
                                .classList.add("block");
                            }
                          }}
                          onMouseLeave={() => {
                            if (entity?.image) {
                              document
                                .getElementById("upload-text")
                                .classList.remove("block");
                              document
                                .getElementById("upload-text")
                                .classList.add("hidden");
                            }
                          }}
                          onClick={() => {
                            document
                              .getElementById("character-image-input")
                              .click();
                          }}
                        />
                        <input
                          type="file"
                          id="character-image-input"
                          accept="image/*"
                          onChange={base64}
                        ></input>
                      </div>
                      <hr className="separator-transparent" />
                      <div className="groupContainer">
                        <div className="row">
                          <div className="column card">
                            <input
                              type="text"
                              id="cName"
                              className="text-white"
                              onBlur={(me) => checkContent(me.target)}
                              defaultValue={entity?.name ?? ""}
                            ></input>
                            <br />
                            <span>
                              <label htmlFor="cName">Character Name</label>
                            </span>
                            <br />
                          </div>
                        </div>
                      </div>

                      <hr className="separator-transparent" />

                      <div className="groupContainer">
                        <div className="row">
                          <div className="column">
                            <input
                              type="text"
                              id="hp"
                              className="text-white"
                              onBlur={(me) => checkContent(me.target)}
                              defaultValue={
                                entity?.meta?.find(
                                  (m) => m.key.toUpperCase() === "HP"
                                )?.value ?? ""
                              }
                            ></input>
                            <br />
                            <span>
                              <label htmlFor="hp">HP</label>
                            </span>
                            <br />
                          </div>
                          <div className="column">
                            <input
                              type="text"
                              id="initiative"
                              className="text-white"
                              onBlur={(me) => checkContent(me.target)}
                              defaultValue={
                                entity?.meta?.find(
                                  (m) => m.key.toUpperCase() === "INITIATIVE"
                                )?.value ?? ""
                              }
                            ></input>
                            <br />
                            <span>
                              <label htmlFor="initiative">Initiative</label>
                            </span>
                            <br />
                          </div>
                        </div>

                        <div className="row">
                          <div className="column">
                            <input
                              type="text"
                              id="movement"
                              className="text-white"
                              onBlur={(me) => checkContent(me.target)}
                              defaultValue={
                                entity?.meta?.find(
                                  (m) => m.key.toUpperCase() === "MOVEMENT"
                                )?.value ?? ""
                              }
                            ></input>
                            <br />
                            <span>
                              <label htmlFor="movement">Movement</label>
                            </span>
                            <br />
                          </div>
                        </div>
                        <div className="row">
                          <div className="column card">
                            <textarea
                              type="text"
                              id="description"
                              className="text-white bg-gray-800 border-white rounded-lg w-full"
                              onBlur={(me) => checkContent(me.target)}
                              defaultValue={entity?.description ?? ""}
                            ></textarea>
                            <br />
                            <span>
                              <label htmlFor="description">Description</label>
                            </span>
                            <br />
                          </div>
                        </div>
                      </div>

                      <hr className="separator-transparent" />
                      <div className="flex">
                        {isEditing && Object.keys(entity).length !== 0 && (
                          <>
                            <button
                              type="button"
                              className="w-1/2 main-button bg-red-700"
                              onClick={() => setOpen(true)}
                            >
                              Delete
                            </button>

                            {open && (
                              <ActionConfirmationModal
                                callbackActionFunction={(e) =>
                                  deleteEntityFromDB(
                                    e,
                                    navigate,
                                    ENTITY_ID,
                                    TABLE_ID,
                                    USER_ID,
                                    sentToDB,
                                    setSentToDB,
                                    setIsEditing,
                                    setIsLoading,
                                    isLoading
                                  )
                                }
                                callbackCancelFunction={() => setOpen(false)}
                                modalTitle={"Delete Entity"}
                                modalDescription={
                                  "Are you sure you want to delete this entity?"
                                }
                                modalAction={"Delete"}
                              />
                            )}
                          </>
                        )}
                        <button
                          type="submit"
                          className={
                            isEditing && Object.keys(entity).length !== 0
                              ? "w-1/2 main-button"
                              : "w-full main-button"
                          }
                        >
                          {isEditing && Object.keys(entity).length !== 0
                            ? "Update"
                            : "Save & Close"}
                        </button>
                      </div>
                    </div>
                  </form>
                </header>
              </div>
              <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="bg-gray-200 text-gray-800 px-4 py-2 rounded shadow-sm hover:bg-gray-300"
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CharacterSheetModal;
