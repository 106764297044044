import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import { IsAuth, verifyToken } from "./middleware/IsAuth/IsAuth";
import EntityManager from "./views/EntityManager/EntityManager";
import GridPage from "./views/GridPage/GridPage";
import Home from "./views/Home/Home";
import Lobbies from "./views/Lobbies/Lobbies";
import Lobby from "./views/Lobby/Lobby";
import LoginPage from "./views/LoginPage/LoginPage";
import Page404 from "./views/Page404/Page404";
import RegisterPage from "./views/RegisterPage/RegisterPage";
import UserManager from "./views/UserManager/UserManager";

// TODO: NotFoundPage

//  Array of urls that we want to exclude from the fetch auth verification
const excludedFetchUrls = [
  "/api/auth/login",
  "/api/auth/register",
  "/api/auth/verify",
];

function App() {
  /** Override the fetch function
   * The fetch function will be called every time a request is made
   * This way we can verify the token before making any request
   * Even the ones that are made from buttons ecc. not only from the routing system
   * @param {*} url
   * @param {*} options
   * @returns
   */
  // Copy the original fetch function
  const originalFetch = window.fetch;
  window.fetch = async (url, options) => {
    //error found, options is undefined with hot reload
    if (options !== undefined) {
      options.headers = {
        ...options.headers,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    }
    // If the url is in the excludedUrls array, do the fetch
    if (excludedFetchUrls.includes(url) || url.includes("hot-update")) {
      return originalFetch(url, options);
    }

    // Verify the token
    if (await verifyToken()) {
      // If the token is valid, do the fetch
      return originalFetch(url, options);
    } else {
      // If the token is not valid, throw an error
      throw new Error("TNV");
    }
  };

  if (window.location.pathname !== "/entityManager") {
    localStorage.removeItem("from");
  }

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route
          path="/lobbies"
          element={
            <IsAuth>
              <Lobbies />
            </IsAuth>
          }
        />
        <Route
          path="/grid"
          element={
            <IsAuth>
              <GridPage />
            </IsAuth>
          }
        />
        <Route
          path="/lobby"
          element={
            <IsAuth>
              <Lobby />
            </IsAuth>
          }
        />
        <Route
          path="/entityManager"
          element={
            <IsAuth>
              <EntityManager />
            </IsAuth>
          }
        />
        <Route
          path="/userManager"
          element={
            <IsAuth>
              <UserManager />
            </IsAuth>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
