import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../../middleware/IsAuth/IsAuth";

function UserManager() {
  const navigate = useNavigate();

  // set page title
  document.title = "TTRPGTA - Account";

  const [userId, setUserId] = useState(null);
  const [submitOpen, setSubmitOpen] = useState(false);
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    if (userId !== null) return;
    const userData = localStorage.getItem("user");
    if (userData === null) {
      navigate("/login");
      return;
    }
    const _id = JSON.parse(userData)._id;
    setUserId(_id);

    if (userData) {
      fetch("api/user/" + _id, {
        method: "GET",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setUsername(data.username);
          setEmail(data.email);
        })
        .catch((err) => {
          if (err.message === "TNV") {
            // Clear the local storage (doing it here to set the buttons in navbar)
            clearLocalStorage();
            // If the token is not valid, redirect to the login page
            navigate("/login");
          }
        });
    }
  }, [userId, navigate]);

  function sendDataToDB() {
    let new_username =
      document.getElementById("usernameInput")?.value || username;
    let new_email = document.getElementById("emailInput")?.value || email;

    fetch("api/user/" + userId, {
      method: "PUT",
      body: JSON.stringify({
        username: new_username,
        email: new_email,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setEmailError("");
          navigate("/login");
        } else {
          throw new Error("Data not valid");
        }
      })
      .catch((err) => {
        if (err.message === "TNV") {
          // If the token is not valid, redirect to the login page
          navigate("/login");
        } else {
          setEmailError("Email already exists");
        }
      });

    setSubmitOpen(false);
  }

  function handleSubmit(newValue, which) {
    let inputUsername = document.getElementById("usernameInput")?.value;
    let inputEmail = document.getElementById("emailInput")?.value;

    if (inputUsername || inputEmail) {
      setSubmitOpen(true);
    } else {
      setSubmitOpen(false);
    }
    if (which === "username") {
      setUsername(newValue);
    } else if (which === "email") {
      setEmailError("");
      setEmail(newValue);
    }
  }

  return (
    <div className="App">
      <main className="main-Container flex-col justify-center">
        <div className="managerContainer">
          <div className="formRow">
            <label className="font-bold" htmlFor="usernameInput">
              Username:{" "}
            </label>

            {username !== null ? (
              <input
                type="text"
                id="usernameInput"
                placeholder={username}
                onChange={(e) => handleSubmit(e.target.value, "username")}
                className="main-input"
                value={username}
              />
            ) : (
              <span className="w-300">loading...</span>
            )}
          </div>
          <div className="formRow mt-4">
            <label className="font-bold" htmlFor="emailInput">
              Email:{" "}
            </label>
            {email !== null ? (
              <input
                type="text"
                id="emailInput"
                placeholder={email}
                onChange={(e) => {
                  handleSubmit(e.target.value, "email");
                }}
                className="main-input"
                value={email}
              />
            ) : (
              <span className="w-300">loading...</span>
            )}
          </div>
          <div id="same-email" className="mt-2 text-red-600 ">
            {emailError}
          </div>
          <p id="submission">
            {submitOpen ? (
              <button onClick={sendDataToDB} className="mt-6 main-button">
                Update fields
              </button>
            ) : (
              <button disabled className="mt-6 main-button-disabled">
                Update fields
              </button>
            )}
          </p>
        </div>
      </main>
    </div>
  );
}
export default UserManager;
