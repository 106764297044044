// Grid component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { socket } from "../../socket";
import LoadingScreenModal from "../Modals/LoadingScreenModal";

function Grid({
  table,
  selectedEntity,
  setSelectedEntity,
  isGridShowed,
  setIsGridShowed,
  isClearing,
  setIsClearing,
  movingEntity,
  setMovingEntity,
}) {
  const table_id = localStorage.getItem("table_id");
  const user = JSON.parse(localStorage.getItem("user"));
  const [grid, setGrid] = useState([]);
  const [bgImage, setImage] = useState("");
  const [submitField, setSubmitField] = useState(false);
  const [isInitializing, setInitialization] = useState(false);
  const [tableManager, setTableManager] = useState(false);

  const [height, setHeight] = useState(table?.grid?.height || 0);
  const [width, setWidth] = useState(table?.grid?.width || 0);
  const [heightToSave, setHeightToSave] = useState();
  const [widthToSave, setwidthToSave] = useState();

  const [moving_cell, setMovingCell] = useState(null);

  useEffect(() => {
    setHeight(table?.grid?.height || 0);
    setWidth(table?.grid?.width || 0);
  }, [table?.grid]);
  const [showGrid, setShowGrid] = useState(isGridShowed());

  // let moving_cell = null;
  const navigate = useNavigate();

  localStorage.removeItem("from");

  const emptyGrid = Array(table?.grid?.height)
    .fill()
    .map(() =>
      Array(table?.grid?.width)
        .fill()
        .map(() => ({
          entity: null,
        }))
    );

  useEffect(() => {
    let new_GridSizes = Array(parseInt(height))
      .fill()
      .map(() =>
        Array(parseInt(width))
          .fill()
          .map(() => ({
            entity: null,
          }))
      );

    setGrid([...new_GridSizes]);
  }, [width, height, isClearing]);

  function pressedCell(cell) {
    let { row, col } = cell.dataset;
    // got a new entity from the entity list
    if (selectedEntity !== null) {
      // if the cell is not empty, return
      if (grid[row][col].entity !== null) {
        // reset the selected entity
        setSelectedEntity(null);
        return;
      }
      // find if there is an entity in the grid with the same entity_id
      if (user._id === table.dm_id) {
        let new_meta = selectedEntity.meta;
        // Filter the meta to remove the TEMP meta
        new_meta = new_meta.filter((meta) => meta.key !== "TEMP");
        // Add the temp meta to the entity
        new_meta.push({
          key: "TEMP",
          value: "true",
        });

        // add the newly created entity to the grid
        let new_grid = grid;
        new_grid[row][col].entity = { ...selectedEntity };
        new_grid[row][col].entity.position = { x: col, y: row };
        setGrid([...new_grid]);

        // Add the entity to the database with a particular meta
        fetch(
          "/api/table/" +
            table_id +
            "/team/" +
            selectedEntity.user_id +
            "/entity",
          {
            method: "POST",
            body: JSON.stringify({
              name: selectedEntity.name,
              image: selectedEntity.image,
              description: selectedEntity.description,
              user_id: selectedEntity.user_id,
              position: { x: col, y: row },
              meta: new_meta,
            }),
          }
        )
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              throw new Error("Data not valid");
            }
          })
          .then((entity) => {
            entity = entity?.new_entity;
            // add the newly created entity to the grid
            let new_grid = grid;
            new_grid[row][col].entity._id = entity._id;
            new_grid[row][col].entity.meta = entity.meta;
            new_grid[row][col].entity.position = { x: col, y: row };

            // send the addEntity signal to the server
            socket.emit("addEntity", {
              table_id: table_id,
              user_id: user._id,
              entity: new_grid[row][col].entity,
              cell: { row, col },
            });

            setGrid([...new_grid]);
          })
          .catch((err) => {
            if (err.message === "TNV") {
              // If the token is not valid, redirect to the login page
              navigate("/login");
            }
          });
      } else {
        let entityInGrid = grid.find((row) =>
          row.find((cell) => cell.entity?._id === selectedEntity._id)
        );

        // if the cell is not empty, return
        if (grid[row][col].entity !== null || entityInGrid) {
          // reset the selected entity
          setSelectedEntity(null);
          return;
        }

        // add the entity to the cell
        let new_grid = grid;
        selectedEntity.position = { x: col, y: row };
        new_grid[row][col].entity = selectedEntity;
        setGrid([...new_grid]);

        // set the background image of the cell
        cell.style.backgroundImage = `url(${selectedEntity.image})`;

        // Save the new position of the entity in the database
        fetch(
          "/api/table/" +
            table_id +
            "/team/" +
            selectedEntity.user_id +
            "/entity/" +
            selectedEntity._id,
          {
            method: "PUT",
            body: JSON.stringify({
              position: { x: col, y: row },
            }),
          }
        ).catch((err) => {
          if (err.message === "TNV") {
            // If the token is not valid, redirect to the login page
            navigate("/login");
          }
        });

        // send the addEntity signal to the server
        socket.emit("addEntity", {
          table_id: table_id,
          user_id: user._id,
          entity: selectedEntity,
          cell: { row, col },
        });

        // reset the selected entity
        if (user._id !== table.dm_id) {
          setSelectedEntity(null);
        }
        return;
      }
      return;
    }

    if (moving_cell === null) {
      if (
        grid[row][col].entity === null ||
        (grid[row][col].entity.user_id !== user._id &&
          table?.dm_id !== user._id)
      ) {
        return;
      }
      setMovingCell({ row, col });
      // change the opacity of the cell
      cell.style.opacity = 0.5;

      // setSelectedEntity(grid[row][col].entity);
      setMovingEntity(grid[row][col].entity);
    } else {
      // if the destination is the same as the source, return
      if (moving_cell.row === row && moving_cell.col === col) {
        resetMovingCell();
        return;
      }

      // move the entity
      if (moveEntity(moving_cell, { row, col })) {
        // reset the image of the cell from where the entity was moved
        document.getElementById(
          `cell-${moving_cell.row}-${moving_cell.col}`
        ).style.backgroundImage = "initial";
        // if the entity was moved, set the image of the cell
        cell.style.backgroundImage = `url(${grid[row][col].entity.image})`;
      }

      resetMovingCell();
    }

    function resetMovingCell() {
      if (moving_cell !== null) {
        document.getElementById(
          `cell-${moving_cell.row}-${moving_cell.col}`
        ).style.opacity = 1;
        setMovingCell(null);
        setMovingEntity(null);
      }
    }
  }

  function moveEntity(from, to, fromWS = false) {
    let new_grid = grid;
    // check if target cell is empty
    if (new_grid[to.row][to.col].entity !== null) {
      // if not empty, return
      return false;
    }

    // get the entity from the cell
    let entity = new_grid[from.row][from.col].entity;

    // remove the entity from the cell
    new_grid[from.row][from.col].entity = null;
    // add the entity to the target cell
    new_grid[to.row][to.col].entity = entity;

    // update the grid
    setGrid([...new_grid]);

    if (!fromWS) {
      // Save the new position of the entity in the database
      fetch(
        "/api/table/" +
          table_id +
          "/team/" +
          entity.user_id +
          "/entity/" +
          entity._id,
        {
          method: "PUT",
          body: JSON.stringify({
            position: { x: to.col, y: to.row },
          }),
        }
      ).catch((err) => {
        if (err.message === "TNV") {
          // If the token is not valid, redirect to the login page
          navigate("/login");
        }
      });

      // send the moveEntity signal to the server
      socket.emit("moveEntity", {
        table_id: table_id,
        user_id: user._id,
        from,
        to,
      });
    }

    return true;
  }

  /**
   * Deletes the entity from the cell, handles the Escape and Delete keys
   * @param {*} key The key pressed on the keyboard
   * @param {*} cell The cell from which the entity will be deleted
   *  Which is the cell that was pressed on
   */
  function deleteEntity(key, cell) {
    let { row, col } = cell.dataset;
    if (grid[row][col].entity === null) return;

    if (key === "Escape") {
      // reset the moving cell and the opacity of the cell
      setMovingCell(null);
      cell.style.opacity = 1;
    } else if (
      (key === "Delete" || key === "Backspace") &&
      (user._id === table?.dm_id || user._id === grid[row][col].entity.user_id)
    ) {
      // If the entity has the TEMP meta, delete it
      if (grid[row][col].entity.meta.find((meta) => meta.key === "TEMP")) {
        fetch(
          "/api/table/" +
            table_id +
            "/team/" +
            grid[row][col].entity.user_id +
            "/entity/" +
            grid[row][col].entity._id,
          {
            method: "DELETE",
          }
        ).catch((err) => {
          if (err.message === "TNV") {
            // If the token is not valid, redirect to the login page
            navigate("/login");
          }
        });
      } else {
        // set the entity position to -1 -1 in the database
        fetch(
          "/api/table/" +
            table_id +
            "/team/" +
            grid[row][col].entity.user_id +
            "/entity/" +
            grid[row][col].entity._id,
          {
            method: "PUT",
            body: JSON.stringify({
              position: { x: -1, y: -1 },
            }),
          }
        ).catch((err) => {
          if (err.message === "TNV") {
            // If the token is not valid, redirect to the login page
            navigate("/login");
          }
        });
      }

      // reset the moving cell
      setMovingCell(null);
      // set the background image of the cell to initial
      cell.style.backgroundImage = "initial";
      // set the opacity of the cell to 1
      cell.style.opacity = 1;
      // remove the entity from the cell
      let new_grid = grid;
      new_grid[row][col].entity = null;
      setGrid([...new_grid]);

      // send the deleteEntity signal to the server
      socket.emit("deleteEntity", {
        table_id: table_id,
        user_id: user._id,
        cell: { row, col },
      });
    }
  }

  function clearGrid() {
    let to_delete = [];
    // Set all positions to -1 -1 in the database for the entities in the grid
    grid.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        if (cell.entity !== null) {
          // If entity has the TEMP meta, delete it
          if (cell.entity.meta.find((meta) => meta.key === "TEMP")) {
            to_delete.push(cell.entity._id);
          } else {
            fetch(
              "/api/table/" +
                table_id +
                "/team/" +
                cell.entity.user_id +
                "/entity/" +
                cell.entity._id,
              {
                method: "PUT",
                body: JSON.stringify({
                  position: { x: -1, y: -1 },
                }),
              }
            ).catch((err) => {
              if (err.message === "TNV") {
                // If the token is not valid, redirect to the login page
                navigate("/login");
              }
            });
          }
        }
      });
    });

    // If to_delete is not empty, delete the entities with the delete /api/table/:id/entities
    if (to_delete.length > 0) {
      fetch("/api/table/" + table_id + "/entities", {
        method: "DELETE",
        body: JSON.stringify({
          entities: to_delete,
        }),
      }).catch((err) => {
        if (err.message === "TNV") {
          // If the token is not valid, redirect to the login page
          navigate("/login");
        }
      });
    }

    let new_GridSizes = Array(parseInt(height))
      .fill()
      .map(() =>
        Array(parseInt(width))
          .fill()
          .map(() => ({
            entity: null,
          }))
      );

    setGrid([...new_GridSizes]);
  }

  /**
   * Initializes the grid with the entities from the table
   * Starts with an empty grid and fills it with the entities from the table
   */
  function initalizeGrid() {
    let grid = emptyGrid;

    table?.team?.forEach((player) => {
      player?.entities?.forEach((entity) => {
        if (
          !isPosInGrid({
            x: entity.position.x,
            y: entity.position.y,
          })
        )
          return;
        grid[entity.position.y][entity.position.x].entity = entity;
        grid[entity.position.y][entity.position.x].entity.user_id =
          player.user_id;
      });
    });

    setGrid([...grid]);
  }

  function isPosInGrid(pos) {
    return pos.x >= 0 && pos.x < width && pos.y >= 0 && pos.y < height;
  }

  // Grid initialization with entities from the table
  useEffect(() => {
    initalizeGrid();
    if (table?.background) {
      setImage(table?.background);
    }
    // eslint-disable-next-line
  }, [table]);

  // Grid cleaning
  useEffect(() => {
    if (isClearing) {
      clearGrid();
      // send the clearGrid signal to the server
      socket.emit("clearGrid", {
        table_id: table_id,
        user_id: user._id,
      });
    }
    setIsClearing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClearing]);

  // Socket listeners
  useEffect(() => {
    if (socket.hasListeners("triggerGrid")) {
      socket.off("triggerGrid");
    }
    if (socket.hasListeners("moveEntity")) {
      socket.off("moveEntity");
    }
    if (socket.hasListeners("deleteEntity")) {
      socket.off("deleteEntity");
    }
    if (socket.hasListeners("addEntity")) {
      socket.off("addEntity");
    }
    if (socket.hasListeners("bgChange")) {
      socket.off("bgChange");
    }
    if (socket.hasListeners("measureChange")) {
      socket.off("measureChange");
    }
    if (socket.hasListeners("gridClearing")) {
      socket.off("gridClearing");
    }
    if (socket.hasListeners("SessionIsOver")) {
      socket.off("SessionIsOver");
    }

    socket.on("triggerGrid", (status) => {
      setIsGridShowed(status);
      setShowGrid(status);
    });

    socket.on("moveEntity", (data) => {
      const { from, to } = data;
      // move the entity
      moveEntity(from, to, true);
    });

    socket.on("deleteEntity", (cell) => {
      const { row, col } = cell;
      // set the background image of the cell to initial
      document.getElementById(`cell-${row}-${col}`).style.backgroundImage =
        "initial";
      // remove the entity from the cell
      let new_grid = grid;
      new_grid[row][col].entity = null;
      setGrid([...new_grid]);
    });

    socket.on("addEntity", (data) => {
      const { cell, entity } = data;
      let new_grid = grid;
      new_grid[cell.row][cell.col].entity = entity;
      setGrid([...new_grid]);
    });

    socket.on("gridClearing", () => {
      let new_grid = emptyGrid;
      setGrid([...new_grid]);
    });

    socket.on("bgChange", (bg) => {
      setImage(bg.image);
    });

    socket.on("measureChange", (gridSizes) => {
      setHeight(gridSizes.heightGrid);
      setWidth(gridSizes.widthGrid);
    });

    socket.on("SessionIsOver", () => {
      localStorage.removeItem("table_id");
      localStorage.removeItem("isGridShowed");
      navigate("/lobbies");
    });
  });

  function bgBase64(e) {
    let file = e.target.files[0];
    let reader = new FileReader();
    // Try to read the file image
    try {
      reader.readAsDataURL(file);
    } catch (error) {}
    reader.onload = function () {
      // Set the image to the one that was read only if it is a data:image
      if (reader.result.toString().includes("data:image")) {
        setImage(reader.result);
        // setInitialization(true);

        fetch("/api/table/" + table_id, {
          method: "PUT",
          body: JSON.stringify({
            background: reader.result,
          }),
        }).catch((err) => {
          if (err.message === "TNV") {
            // If the token is not valid, redirect to the login page
            navigate("/login");
          }
        });

        socket.emit("changeBackground", {
          table_id: localStorage.getItem("table_id"),
          user_id: user._id,
          image: reader.result,
        });
      }
    };
  }

  useEffect(() => {
    socket.emit("changeBackground", {
      table_id: localStorage.getItem("table_id"),
      user_id: user._id,
      image: bgImage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetch("/api/table/" + table_id, {
      method: "PUT",
      body: JSON.stringify({
        background: bgImage,
      }),
    }).catch((err) => {
      if (err.message === "TNV") {
        // If the token is not valid, redirect to the login page
        navigate("/login");
      }
    });
    // eslint-disable-next-line
  }, [isInitializing]);

  function saveFieldsInDB() {
    fetch("/api/table/" + table_id, {
      method: "PUT",
      body: JSON.stringify({
        grid: {
          height: height,
          width: width,
        },
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Data not valid");
        }
      })
      .then(() => {
        setHeightToSave(height);
        setwidthToSave(width);
        socket.emit("gridSizeChange", {
          table_id: table_id,
          heightGrid: height,
          widthGrid: width,
        });
      })
      .catch((error) => {
        if (error.message === "TNV") {
          navigate("/login");
        }
      });

    setSubmitField(false);
    setTableManager(!tableManager);
  }

  function handleHeight(target, which) {
    setSubmitField(true);
    if (target) {
      setHeight(target);
    } else {
      setHeight(1);
    }
  }

  function handleWidth(target, which) {
    setSubmitField(true);
    if (target) {
      setWidth(target);
    } else {
      setWidth(1);
    }
  }

  function triggerMapVisibility() {
    socket.emit("triggerGrid", {
      value: !showGrid,
      table_id: table_id,
      user_id: user._id,
    });
    document.getElementById("squaredGrid");

    setIsGridShowed(!showGrid);
    setShowGrid(!showGrid);
  }

  function tableManagerOpens() {
    setTableManager(true);
    setHeightToSave(height);
    setwidthToSave(width);
  }

  function closeManager() {
    if (submitField) {
      setHeight(heightToSave);
      setWidth(widthToSave);
    }
    setTableManager(false);
  }

  return (
    <div className="grid">
      {JSON.parse(localStorage.getItem("user"))._id === table?.dm_id ? (
        tableManager ? (
          <div className="flex flex-col my-8 font-bold">
            <span className="text-center">
              Please note that by changing the size of the grid it will
              automatically be cleared.
            </span>
            <div className="flex justify-center items-center mt-4">
              <button
                id="Tmanager"
                onClick={closeManager}
                className="main-button"
              >
                Close Manager
              </button>
              <div>
                <div className="flex flex-col mx-2">
                  <input
                    id="heightInput"
                    className="text-black"
                    type="number"
                    placeholder="height"
                    onChange={(e) => handleHeight(e.target.value)}
                    value={height}
                    min={0}
                  />
                  <input
                    id="widthInput"
                    className="text-black"
                    type="number"
                    placeholder="width"
                    onChange={(e) => handleWidth(e.target.value)}
                    value={width}
                    min={0}
                  />
                  {submitField ? (
                    <button
                      onClick={saveFieldsInDB}
                      className="mt-6 main-button"
                    >
                      Update size
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-center items-center relative">
                <button
                  className="main-button"
                  onClick={() => {
                    document.getElementById("bg-image-input").click();
                  }}
                >
                  Upload grid background
                </button>
                <input
                  type="file"
                  id="bg-image-input"
                  accept="image/*"
                  onChange={bgBase64}
                />
              </div>

              <button
                className="ms-2 main-button"
                onClick={triggerMapVisibility}
              >
                {showGrid ? "Hide map" : "Show map"}
              </button>
            </div>
          </div>
        ) : (
          <button
            id="Tmanager"
            onClick={tableManagerOpens}
            className="main-button mx-auto my-4"
          >
            Table Manager
          </button>
        )
      ) : null}

      {!showGrid &&
      JSON.parse(localStorage.getItem("user"))._id !== table?.dm_id ? (
        <div className="justify-center flex flex-col items-center">
          <LoadingScreenModal conditionToShow={!showGrid} />
        </div>
      ) : null}
      <div
        id="grid-container"
        className={
          (user._id === table?.dm_id ? true : showGrid)
            ? "max-h-screen overflow-auto pt-4"
            : "hidden"
        }
      >
        {/* Actuall Grid */}
        <div
          className="bg-no-repeat bg-cover bg-center grid h-fit w-fit"
          style={{
            gridTemplateRows: `repeat(${grid?.length || 0}, 50px)`,
            gridTemplateColumns: `repeat(${grid[0]?.length || 0}, 50px)`,
            backgroundImage: `url( ${bgImage} )`,
          }}
          id="squaredGrid"
          onKeyDown={(me) => {
            if (
              me.key === "Delete" ||
              me.key === "Backspace" ||
              me.key === "Escape"
            ) {
              deleteEntity(me.key, me.target);
            }
          }}
        >
          {grid.map((row, rowIndex) => {
            return row.map((cell, colIndex) => {
              const cellId = `cell-${rowIndex}-${colIndex}`; // Generate a unique id for the cell
              return (
                <button
                  id={cellId}
                  key={cellId}
                  data-row={rowIndex}
                  data-col={colIndex}
                  onClick={(e) => {
                    pressedCell(e.target);
                  }}
                  // make the background image fit the cell
                  style={{
                    // background: "initial",
                    backgroundSize: "cover",
                    // if the cell has an entity set the background image
                    backgroundImage: cell.entity
                      ? `url(${cell.entity.image})`
                      : "initial",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                  className="grid-item"
                  // Set as the background image the one in the cell
                ></button>
              );
            });
          })}
        </div>
      </div>
    </div>
  );
}
export default Grid;
