import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import EntityCard from "../EntityCard/EntityCard";
import EditCharacterModal from "./EditCharacterModal";

function EntitiesManagerModal({
  modalTitle,
  modalDescription,
  dm,
  table_id,
  entities = [],
  callbackCloseFunction,
  setDeletedEntity,
  deletedEntity,
  openConfirmationModal,
  openEntitiesManagerModal,
  actionSetter,
  descriptionSetter,
  titleSetter,
  callbackSetter,
  setTrigger,
  isLoading,
  setIsLoading,
}) {
  const [choosenEntities, setChoosenEntities] = useState([]);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);

  let navigate = useNavigate();

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white text-modal p-6 rounded shadow-lg relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="black"
            className="w-6 h-6 absolute top-2 right-2 text-modal cursor-pointer"
            onClick={callbackCloseFunction}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <h3 className="text-lg font-semibold text-gray-900">{modalTitle}</h3>
          <p className="mb-4">{modalDescription}</p>
          <main className="grid grid-cols-3 gap-3 max-h-[50vh] overflow-auto m-4">
            {entities.map((entity, index) =>
              // If the entity has the meta TEMP, we don't show it
              entity.meta.find((meta) => meta.key === "TEMP") ? null : (
                <span
                  key={uuidv4()}
                  id={`card-${index}`}
                  onClick={() => {
                    if (choosenEntities.includes(entity)) {
                      setChoosenEntities(
                        choosenEntities.filter((e) => e !== entity)
                      );
                    } else {
                      setChoosenEntities([...choosenEntities, entity]);
                    }
                  }}
                  className={`${
                    choosenEntities.includes(entity) ? "selected-card" : ""
                  }`}
                >
                  <EntityCard
                    entity={entity}
                    entityIndex={index}
                    key={uuidv4()}
                    isEditable={false}
                    managerView={false}
                    setIsEditing={false}
                    setSelectedEntity={undefined}
                    importView={true}
                  />
                </span>
              )
            )}
          </main>
          {/* TODO: can be usefull to add here the "import function" */}
          <button
            className={`${
              choosenEntities.length > 1 ||
              choosenEntities < 1 ||
              isButtonsDisabled
                ? "bg-gray-500"
                : "bg-green-500 hover:bg-green-600"
            } text-white px-4 py-2 rounded shadow-sm mr-2`}
            onClick={() => {
              setIsOpenEdit(true);
            }}
            disabled={
              choosenEntities.length > 1 ||
              choosenEntities < 1 ||
              isButtonsDisabled
            } // Disable the button if select-many is checked
          >
            Edit
          </button>
          <button
            className={`${
              choosenEntities.length === 0 || isButtonsDisabled
                ? "bg-gray-500"
                : "bg-red-500 hover:bg-red-600"
            } text-white px-4 py-2 rounded shadow-sm mr-2`}
            onClick={() => {
              actionSetter("Delete");
              descriptionSetter(
                "Are you sure you want to delete this/these entity/entities?"
              );
              titleSetter("Delete entity/entities");
              callbackSetter(() => async () => {
                setIsButtonsDisabled(true);
                setIsLoading(true);
                try {
                  for (const entity of choosenEntities) {
                    const response = await fetch(
                      `api/table/${table_id}/team/${dm.user_id}/entity/${entity._id}`,
                      {
                        method: "DELETE",
                      }
                    );
                    if (!response.ok && response.status !== 304) {
                      throw new Error("Table, user, or entity not found");
                    }
                  }
                  openConfirmationModal(false);
                  setTrigger(null);
                  setIsLoading(false);
                  setDeletedEntity(!deletedEntity);
                } catch (error) {
                  setIsLoading(false);
                  // if error is TNV (token not valid), redirect to login page using navigate
                  if (error.message === "TNV") {
                    navigate("/login");
                  }
                }
              });
              openEntitiesManagerModal(false);
              openConfirmationModal(true);
            }}
            disabled={choosenEntities.length === 0 || isButtonsDisabled} // Disable the button if no entity is selected
          >
            Delete {choosenEntities.length} entities
          </button>
        </div>
        {isOpenEdit && (
          <EditCharacterModal
            modalTitle="Edit your character"
            player={dm}
            choosenEntity={choosenEntities[0]}
            callbackSaveFunction={(dm, entity) => {
              setIsLoading(true);
              fetch(
                "api/table/" +
                  table_id +
                  "/team/" +
                  dm.user_id +
                  "/entity/" +
                  entity._id,
                {
                  method: "PUT",
                  body: JSON.stringify(entity),
                }
              )
                .then((res) => res.json())
                .then((data) => {
                  setIsLoading(false);
                  setIsOpenEdit(false);
                })
                .catch((error) => {
                  setIsLoading(false);
                  // if error is TNV (token not valid), redirect to login page using navigate
                  if (error.message === "TNV") {
                    navigate("/login");
                  }
                });
            }}
            callbackSaveInEntitiesFunction={(dm, entity) => {
              setIsLoading(true);
              fetch(
                "api/table/" +
                  JSON.parse(localStorage.getItem("user")).entities_id +
                  "/team/" +
                  dm.user_id +
                  "/entity/",
                {
                  method: "POST",
                  body: JSON.stringify(entity),
                }
              )
                .then((res) => res.json())
                .then((data) => {
                  setIsLoading(false);
                })
                .catch((error) => {
                  setIsLoading(false);
                  // if error is TNV (token not valid), redirect to login page using navigate
                  if (error.message === "TNV") {
                    navigate("/login");
                  }
                });
            }}
            callbackCancelFunction={() => setIsOpenEdit(false)}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}

export default EntitiesManagerModal;
