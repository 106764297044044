import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ActionConfirmationModal from "../../components/Modals/ActionConfirmationModal";
import AddLobbyModal from "../../components/Modals/AddLobbyModal";
import { socket } from "../../socket";

function Lobbies() {
  const navigate = useNavigate();

  // set page title
  document.title = "TTRPGTA - Lobbies";

  // fetch from backend the tables
  const [tables, setTables] = useState([]);
  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  // are we leaving or deleting
  const [isLeave, setIsLeave] = useState(false);
  const [selectedTable, setSelectedTable] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // get userId from localStorage
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user._id;

  function PushCards({ cards, isDM }) {
    if (cards === undefined) {
      return;
    }
    return cards.map((card, index) => (
      <div key={uuidv4()} className="card">
        <span className="text-bold flex overflow-hidden">{card.name}</span>{" "}
        <br />
        <span className="block">{card.table_code}</span>
        <button
          className="cardLink"
          onClick={() => {
            goToLobby(card._id, isDM);
          }}
        >
          Join
        </button>
        {!isDM ? (
          <button
            onClick={() => {
              setIsLeave(true);
              setSelectedTable(card);
              setOpenConfirmationModal(true);
            }}
            className="cardLink"
          >
            Leave
          </button>
        ) : null}
        {isDM ? (
          <button
            className="cardLink"
            onClick={() => {
              setIsLeave(false);
              setSelectedTable(card);
              setOpenConfirmationModal(true);
            }}
          >
            Delete
          </button>
        ) : null}
      </div>
    ));
  }

  function deleteTable(table) {
    // Delete table request
    fetch("api/table/" + table._id, {
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok && response.status !== 304) {
          throw new Error("Server error");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        // Delete table and send the information to the players
        // This will delete the table from other user's view
        socket.emit("deletedTable", {
          table: table,
        });
        setTables((prev) => {
          if (table.dm_id === userId) {
            return {
              ...prev,
              dm: prev.dm.filter((item) => item._id !== table._id),
            };
          } else {
            return {
              ...prev,
              player: prev.player.filter((item) => item._id !== table._id),
            };
          }
        });
        setOpenConfirmationModal(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.message === "TNV") {
          navigate("/login");
        }
      });
  }

  useEffect(() => {
    if (socket.hasListeners("tableDeletion")) {
      socket.off("tableDeletion");
    }
    socket.on("tableDeletion", (data) => {
      setTables((prev) => {
        if (data.dm_id === userId) {
          return {
            ...prev,
            dm: prev.dm.filter((item) => item._id !== data._id),
          };
        } else {
          return {
            ...prev,
            player: prev.player.filter((item) => item._id !== data._id),
          };
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function goToLobby(table_id, isDM) {
    // Signal that the user has entered the lobby
    // This will create the webSocket room if it doesn't exist
    socket.emit("enteredLobby", {
      user_id: userId,
      table_id: table_id,
      is_dm: isDM,
    });
    // Save in localStorage
    localStorage.setItem("table_id", table_id);
    navigate("/lobby");
  }

  function leaveTable(table) {
    // delete /api/table
    fetch("api/table/" + table._id + "/team/" + userId, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        setTables((prev) => {
          if (table.dm_id === userId) {
            return {
              ...prev,
              dm: prev.dm.filter((item) => item._id !== table._id),
            };
          } else {
            return {
              ...prev,
              player: prev.player.filter((item) => item._id !== table._id),
            };
          }
        });
        socket.emit("leaveTable", user.username);
        setOpenConfirmationModal(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.message === "TNV") {
          // If the token is not valid, redirect to the login page
          navigate("/login");
        }
      });
  }

  function joinTable(event) {
    if (!event) {
      toast.remove();
      toast.error("You have to provide a table code...");
      return;
    }
    event.preventDefault();
    setIsLoading(true);
    const table_code = event.target.value;
    let table_id = "";
    // get /api/table/table_code
    fetch("/api/table/" + table_code)
      .then((res) => {
        if (!res.ok) {
          toast.remove();
          toast.error("Table with given ID not found");
          throw new Error("Table not found!");
        }
        return res.json();
      })
      .then((data) => {
        table_id = data._id;
        if (table_id === "") {
          return;
        }

        // Table found, add the user as player
        fetch("/api/table/" + table_id + "/team", {
          method: "POST",
          body: JSON.stringify({
            user_id: userId,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              toast.remove();
              toast.error("Table already joined");
              throw new Error("Cannot join the table");
            }
            return res.json();
          })
          .then((data) => {
            // Add the data to the table.player array
            setTables((prev) => {
              return { ...prev, player: [...prev.player, data] };
            });
            socket.emit("joinTable", user.username);
            setIsLoading(false);
            goToLobby(table_id);
          })
          .catch((err) => {
            setIsLoading(false);
            if (err.message === "TNV") {
              // If the token is not valid, redirect to the login page
              navigate("/login");
            }
          });
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.message === "TNV") {
          // If the token is not valid, redirect to the login page
          navigate("/login");
        }
      });
  }

  function createTable(event) {
    if (!event) {
      toast.remove();
      toast.error("You have to provide a name...");
      return;
    }
    event.preventDefault();
    setIsLoading(true);
    const table_name = event.target.value;
    // post /api/table
    fetch("/api/table", {
      method: "POST",
      body: JSON.stringify({
        dm_id: userId,
        name: table_name,
        grid: {
          height: 20,
          width: 20,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // Add the data to the table.dm array
        setTables((prev) => {
          return { ...prev, dm: [...prev.dm, data] };
        });
        setIsCreateModalOpen(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.message === "TNV") {
          // If the token is not valid, redirect to the login page
          navigate("/login");
        }
      });
  }

  useEffect(() => {
    // We do 2 .then because the first one returns a promise (which contains the status code)
    // and the second one returns the actual data
    fetch("/api/user/" + userId + "/table")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTables(data);
      })
      .catch((err) => {
        if (err.message === "TNV") {
          // If the token is not valid, redirect to the login page
          navigate("/login");
        }
      });
  }, [navigate, userId]);

  useEffect(() => {
    if (localStorage.getItem("kicked")) {
      let customToastText = localStorage.getItem("kicked");
      toast.remove();
      toast.custom(
        (t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } dismissibleToastText`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="ml-3 flex-1">
                  <p className="text-gray-900">
                    You were kicked from table: {customToastText}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="dismissibleToastButton"
              >
                Dismiss
              </button>
            </div>
          </div>
        ),
        { duration: Infinity }
      );
      localStorage.removeItem("kicked");
    }
  }, []);

  return (
    <div className="App">
      {openConfirmationModal && (
        <ActionConfirmationModal
          callbackActionFunction={() => {
            setIsLoading(true);
            isLeave ? leaveTable(selectedTable) : deleteTable(selectedTable);
            setSelectedTable("");
          }}
          callbackCancelFunction={() => {
            setSelectedTable("");
            setOpenConfirmationModal(false);
          }}
          modalTitle={isLeave ? "Leaving table" : "Deleting table"}
          modalDescription={
            "Are you sure you want to " +
            (isLeave ? "leave" : "delete") +
            " this table?"
          }
          modalAction={isLeave ? "Leave table" : "Delete table"}
          isLoading={isLoading}
        />
      )}
      <Toaster />
      <div className="main-Container justify-center">
        <Toaster position="top-center" reverseOrder={false} />
        <div className="container ">
          <button
            className="main-button"
            onClick={() => setIsJoinModalOpen(true)}
          >
            Join a Campaign
          </button>
          {/* Join as Player */}

          <div className="row slider pt-2">
            <PushCards cards={tables.player} isDM={false} />
          </div>
          <hr className="m-2" />
          <button
            className="main-button"
            onClick={() => setIsCreateModalOpen(true)}
          >
            Create a new Campaign
          </button>
          {/* Create a new Campaign as DM*/}
          <div className="row slider pt-2">
            <PushCards cards={tables.dm} isDM={true} />
          </div>
        </div>
      </div>
      {isJoinModalOpen && (
        <AddLobbyModal
          modalTitle="Join lobby as Player ..."
          actionName="Join Table"
          inputPlaceholder={"Table ID..."}
          callbackActionFunction={(event) => {
            joinTable(event);
          }}
          callBackCancelFunction={() => setIsJoinModalOpen(false)}
          isLoading={isLoading}
        />
      )}
      {isCreateModalOpen && (
        <AddLobbyModal
          modalTitle="Create your new Campaign as DM ..."
          actionName="Create Campaign"
          inputPlaceholder={"Campaign Name..."}
          callbackActionFunction={(event) => {
            createTable(event);
          }}
          callBackCancelFunction={() => setIsCreateModalOpen(false)}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default Lobbies;
