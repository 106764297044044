import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { socket } from "../../socket";

/** Verify the token
 * It will get the token from the localStorage and
 * send a request to the backend to verify it to the endpoint /api/auth/verify
 * @returns true if the token is valid, false otherwise
 */
const verifyToken = async () => {
  // Verify with a request to the backend if the token is valid
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");

  if (token) {
    let result = await fetch("/api/auth/verify", {
      method: "POST",
      body: JSON.stringify({ token, user }),
    })
      .then((res) => {
        if (!res.ok) {
          return { errors: [{ msg: "Token is not valid" }] };
        } else {
          return res.json();
        }
      })
      .then((data) => {
        if (data.errors) {
          return false;
        } else {
          // Save the auth-token in the local storage
          localStorage.setItem("token", data.token);
          setSocketId();
          return true;
        }
      })
      .catch((err) => {
        return false;
      });
    if (!result) {
      clearLocalStorage();
    }
    return result;
  }
  clearLocalStorage();
  return false;
};

function setSocketId() {
  const user = JSON.parse(localStorage.getItem("user"));
  const table_id = localStorage.getItem("table_id");
  socket.emit("setSocketId", {
    user_id: user._id,
    table_id: table_id,
  });
}

const clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("table_id");
  localStorage.removeItem("entity_id");
  localStorage.removeItem("entity_info");
};

/** ProtectedRoute component
 * This component will be used to protect the routes for which the user must be logged in
 * It will first render a loading screen, then it will verify the token
 * If the token is valid, it will render the children (the component that we want to render)
 * If the token is not valid, it will redirect to the login page
 * @param {*} children - Component to render
 * @returns the children if the user is logged in, otherwise a Navigate component to the login page
 */
const IsAuth = ({ children }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);

  useEffect(() => {
    const tokenCheck = async () => {
      // Verify with a request to the backend if the token is valid
      const isValid = await verifyToken();
      setIsTokenValid(isValid);
      setIsLoading(false);
    };

    tokenCheck();
  }, []);

  if (isLoading) {
    return <header className="main-Container flex-col justify-center"></header>;
  }

  return isTokenValid ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export { IsAuth, verifyToken, clearLocalStorage };
