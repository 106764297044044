import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../../middleware/IsAuth/IsAuth";

function LoginPage() {
  let navigate = useNavigate();

  // set page title
  document.title = "TTRPGTA - Login";

  useEffect(() => {
    // In the login page, we clear the local storage
    if (localStorage.getItem("firstTime")) {
      clearLocalStorage();
      localStorage.setItem("firstTime", "dismissed");
    } else {
      clearLocalStorage();
    }
  }, []);

  return (
    <div className="App">
      <header className="main-Container justify-center flex-col">
        <form onSubmit={handleSubmit}>
          <div className="formRow">
            <label className="font-bold" htmlFor="email">
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="main-input"
              placeholder="name@some.some"
            />
          </div>
          <div className="formRow">
            <label className="font-bold" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="password"
              className="main-input"
            />
          </div>
          <div id="login-errors"></div>
          <input type="submit" value="Login" className="main-button" />
        </form>
        <a href="/register" className="mt-6 text-cyan-400">
          Register here
        </a>
      </header>
    </div>
  );

  function handleSubmit(event) {
    event.preventDefault();
    const data = {
      email: event.target.email.value,
      password: event.target.password.value,
    };

    fetch("/api/auth/login", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          let errors = document.getElementById("login-errors");
          errors.innerHTML = "";
          data.errors.forEach((error) => {
            errors.innerHTML += error.msg + "<br />";
          });
        } else {
          // Save the auth-token in the local storage
          localStorage.setItem("token", data.token);
          // Save the user in localStorage
          localStorage.setItem("user", JSON.stringify(data.user));

          // Can't do them togheter because the route for the entities/id is protected
          // so i add first the user to localStorage, and then I add the entities_id
          // Get the ENTITIES table id from database
          fetch("/api/user/" + data.user._id + "/entities/id")
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error("Something went wrong");
              }
            })
            .then((entities_id) => {
              // Save the ENTITIES table id in localStorage
              let user_ls = JSON.parse(localStorage.getItem("user"));
              user_ls.entities_id = entities_id;
              localStorage.setItem("user", JSON.stringify(user_ls));
            })
            .catch((error) => {
              navigate("/login");
            });

          // Using router-dom go to the home page
          navigate("/");
        }
      })
      .catch((error) => {
        navigate("/login");
      });
  }
}

export default LoginPage;
