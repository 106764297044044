import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CharactersList from "../../components/Grid/CharactersList";
import DmSideManager from "../../components/Grid/DmSideManager";
import Grid from "../../components/Grid/Grid";
import { socket } from "../../socket";

function GridPage() {
  const navigate = useNavigate();
  const [table, setTable] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [isClearing, setIsClearing] = useState(false);
  const [deletedEntity, setDeletedEntity] = useState(false);
  const [movingEntity, setMovingEntity] = useState(null);

  // set page title
  document.title = "TTRPGTA - " + (table?.name || "loading...");

  useEffect(() => {
    if (isFetching) return;
    let TABLE_ID = localStorage.getItem("table_id");
    if (TABLE_ID === null) {
      navigate("/lobbies");
      return;
    }
    fetch("/api/table/" + TABLE_ID, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        setTable(data);
        setIsFetching(true);
      })
      .catch((err) => {
        if (err.message === "TNV") {
          // If the token is not valid, redirect to the login page
          navigate("/login");
        }
      });
  }, [isFetching, navigate]);

  useEffect(() => {
    if (!localStorage.getItem("firstTime")) {
      toast.remove();
      toast.custom(
        (t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } dismissibleToastText`}
          >
            <a
              href="/userManual.pdf"
              download="TTRPGTA User Manual"
              className="contents "
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start ">
                  <div className="ml-3 flex-1">
                    <p className="text-gray-900">
                      Is this your first time in TTRPGTA? Click here to learn
                      how to use this tool:
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => {
                  toast.dismiss(t.id);
                  localStorage.setItem("firstTime", "dismissed");
                }}
                className="dismissibleToastButton"
              >
                Dismiss
              </button>
            </div>
          </div>
        ),
        { duration: Infinity }
      );
    }
  }, []);

  useEffect(() => {
    socket.emit("reFetchGrid", {
      table_id: localStorage.getItem("table_id"),
      user_id: JSON.parse(localStorage.getItem("user"))._id,
    });
    // Fetch the table
    fetchTable();
    // eslint-disable-next-line
  }, [deletedEntity]);

  // Setup websockets listeners
  useEffect(() => {
    if (socket.hasListeners("reFetchGrid")) {
      socket.off("reFetchGrid");
    }

    socket.on("reFetchGrid", () => {
      fetchTable();
    });
    // eslint-disable-next-line
  }, []);

  function fetchTable() {
    // Fetch the table
    let TABLE_ID = localStorage.getItem("table_id");
    if (TABLE_ID === null) {
      navigate("/lobbies");
      return;
    }
    fetch("/api/table/" + TABLE_ID, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        setTable(data);
      })
      .catch((err) => {
        if (err.message === "TNV") {
          // If the token is not valid, redirect to the login page
          navigate("/login");
        }
      });
  }

  function setIsGridShowed(value) {
    localStorage.setItem("isGridShowed", value);
  }

  function isGridShowed() {
    return localStorage.getItem("isGridShowed") === "true";
  }

  return (
    <main className="flex w-full">
      <Toaster />
      <div
        id="characters-container"
        className="max-h-screen overflow-y-auto p-6 pt-0"
      >
        <div className="flex justify-center items-center px-4">
          <button
            className="main-button my-4"
            onClick={() => {
              document
                .getElementById("characters-component")
                .classList.toggle("hidden");
              document
                .getElementById("characters-container")
                .classList.toggle("min-w-min");
              if (
                JSON.parse(localStorage.getItem("user"))._id === table.dm_id
              ) {
                document
                  .getElementById("grid-container")
                  .classList.toggle("w-fit");
              } else {
                document
                  .getElementById("grid-container")
                  .classList.toggle("w-[100%]");
              }
            }}
          >
            Toggle characters
          </button>
        </div>
        <CharactersList
          table={table}
          setSelectedEntity={setSelectedEntity}
          selectedEntity={selectedEntity}
        />
      </div>
      <div id="grid-container" className={`px-4`}>
        <Grid
          table={table}
          isGridShowed={isGridShowed}
          setIsGridShowed={setIsGridShowed}
          selectedEntity={selectedEntity}
          setSelectedEntity={setSelectedEntity}
          isClearing={isClearing}
          setIsClearing={setIsClearing}
          movingEntity={movingEntity}
          setMovingEntity={setMovingEntity}
        />
      </div>
      {JSON.parse(localStorage.getItem("user"))._id === table.dm_id ? (
        <div className="w-[25%] px-4">
          <DmSideManager
            isGridShowed={isGridShowed}
            setIsGridShowed={setIsGridShowed}
            setSelectedEntity={setSelectedEntity}
            setIsClearing={setIsClearing}
            selectedEntity={selectedEntity}
            table={table}
            setDeletedEntity={setDeletedEntity}
            deletedEntity={deletedEntity}
            movingEntity={movingEntity}
          />
        </div>
      ) : null}
    </main>
  );
}
export default GridPage;
