import { useNavigate } from "react-router-dom";

function RegisterPage() {
  let navigate = useNavigate();

  // set page title
  document.title = "TTRPGTA - Register";

  return (
    <div className="App">
      <header className="main-Container flex-col justify-center">
        <form onSubmit={handleSubmit}>
          <div className="formRow">
            <label className="font-bold" htmlFor="username">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="username"
              className="main-input"
            />
          </div>
          <div className="formRow">
            <label className="font-bold" htmlFor="email">
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="name@some.some"
              className="main-input"
            />
          </div>
          <div className="formRow">
            <label className="font-bold" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="password"
              className="main-input"
            />
          </div>
          <div id="register-errors"></div>
          <input type="submit" value="Register" className="main-button" />
        </form>
        <a href="/login" className="mt-6 text-cyan-400">
          Login here
        </a>
      </header>
    </div>
  );

  function handleSubmit(event) {
    event.preventDefault();
    const data = {
      username: event.target.username.value,
      email: event.target.email.value,
      password: event.target.password.value,
    };

    fetch("/api/auth/register", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          let errors = document.getElementById("register-errors");
          errors.innerHTML = "";
          data.errors.forEach((error) => {
            errors.innerHTML += error.msg + "<br />";
          });
        } else {
          // Using router-dom go to the home page
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export default RegisterPage;
