import { v4 as uuidv4 } from "uuid";
import EntityCard from "../EntityCard/EntityCard";
import { useEffect, useState } from "react";
import { socket } from "../../socket";

const user = localStorage.getItem("user");
let USER_ID = "";
if (user) {
  USER_ID = JSON.parse(user)._id;
}

function CharactersList({ table, setSelectedEntity, selectedEntity }) {
  const [team, setTeam] = useState(table?.team);

  useEffect(() => {
    setTeam(table?.team);
  }, [table]);

  return (
    <div id="characters-component" className="flex flex-col">
      {team?.slice(1).map((player, index) => (
        <EntityCard
          key={uuidv4()}
          entity={player.entities[0]}
          entityIndex={uuidv4()}
          isEditable={player?.user_id === USER_ID}
          managerView={false}
          setSelectedEntity={setSelectedEntity}
          selectedEntity={selectedEntity}
          entityOwner={player.user_id}
          table={table}
          callbackRefetchTable={() => {
            socket.emit("reFetchGrid", {
              table_id: localStorage.getItem("table_id"),
              user_id: JSON.parse(localStorage.getItem("user"))._id,
            });
          }}
        />
      ))}
    </div>
  );
}
export default CharactersList;
