import { v4 as uuidv4 } from "uuid";
import { Tooltip } from "react-tooltip";

let authors = [
  { name: "Davide Frova", gitName: "frovaaa" },
  { name: "Matteo Ghilardini", gitName: "ghi-la" },
  { name: "Giorgia Lillo", gitName: "GioLillo" },
  { name: "Lamberto Ragnolini", gitName: "lambione" },
  { name: "Francesc Jordi Sacco", gitName: "sccjrd" },
  { name: "Sasha Toscano", gitName: "Fireblast9" },
];

// set page title
document.title = "TTRPGTA - Home";

function Home() {
  return (
    <div className="App">
      <header className="main-Container justify-center flex-col">
        <p>
          Welcome to the Tabletop Role-playing Game Table Assistant (TTRPGTA)!
        </p>
        <p className="px-[20%] mb-8">
          TTRPGTA is a table assistant meant to be used when you're playing your
          favourite TTRPGs (D&D, Pathfinder, Starfinder, etc...) to make your
          experience smoother, more interactive and to eliminate the need to buy
          an infinite amount of miniatures and props!
        </p>
        <p className="px-[20%] mb-8">
          The idea of this project is not to recreate a VTT, instead to use a
          touch-screen to create a virual replacement for your paper-grid.
          Therefore, to make proper use of this application you will need to use
          a PC or at least a monitor.
        </p>
        {localStorage.getItem("token") ? null : (
          <p className="px-[20%] mb-8">
            If this is something that you believe is interesting or you're maybe
            just willing to have a look around, don't hesitate to join us in a
            few steps: just <a href="/register">register here</a> and soon
            you'll be able to create your own virtual table!
          </p>
        )}
        <p className="px-[20%] mb-8">
          If you want to get started with TTRPGTA you can{" "}
          <a className="text-cyan-400" href="/userManual.pdf" target="_blank">
            read
          </a>{" "}
          or{" "}
          <a
            className="text-cyan-400"
            href="/userManual.pdf"
            download="TTRPGTA User Manual"
          >
            download
          </a>{" "}
          our user <span id="easter-egg">manual</span>
          <Tooltip anchorSelect="#easter-egg">Is not manual, is Manuel</Tooltip>
        </p>
        <p className="px-[20%] mb-8">TTRPGTA is a SA3 project developed by:</p>
        <ul className="authorList">
          {authors.map((author) => (
            <li key={uuidv4()}>
              {author.name}
              <a
                href={"https://github.com/" + author.gitName}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/github_icon.png"
                  className="gitLogo"
                  alt="Github Logo"
                ></img>
              </a>
            </li>
          ))}
        </ul>
      </header>
    </div>
  );
}

export default Home;
