function ActionConfirmationModal({
  modalTitle,
  modalDescription,
  modalAction,
  callbackActionFunction,
  callbackCancelFunction,
  isLoading = false,
}) {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50">
      <div className="flex items-center justify-center min-h-screen">
        <div
          className={
            (isLoading ? "bg-transparent" : "bg-modal") +
            " text-modal p-6 rounded shadow-lg relative"
          }
        >
          {isLoading ? (
            <img src="images/loading.gif" alt="loading gif" />
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="black"
                className="w-6 h-6 absolute top-2 right-2 text-modal cursor-pointer"
                onClick={callbackCancelFunction}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>

              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                {modalTitle}
              </h3>
              <p className="text-gray-500 mb-4">{modalDescription}</p>
              <div className="flex justify-end">
                <button
                  className={`${
                    isLoading ? "" : "bg-red-500 hover:bg-red-600"
                  } text-white px-4 py-2 rounded shadow-sm  mr-2`}
                  onClick={callbackActionFunction}
                  disabled={isLoading}
                >
                  {modalAction}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ActionConfirmationModal;
