import React, { useState } from "react";

function AddLobbyModal({
  modalTitle,
  actionName,
  inputPlaceholder,
  callbackActionFunction,
  callBackCancelFunction,
  isLoading = false,
}) {
  const [inputEvent, setInputEvent] = useState(null);
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50">
      <div className="flex items-center justify-center min-h-screen">
        {isLoading ? (
          <img src="images/loading.gif" alt="loading gif" />
        ) : (
          <div className="bg-white p-6 rounded shadow-lg">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">
              {modalTitle}
            </h3>

            <input
              id="modalInput"
              className="text-black"
              placeholder={inputPlaceholder}
              onChange={(e) => {
                setInputEvent(e);
              }}
            />
            <hr className="my-4" />
            <div className="flex justify-end">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded shadow-sm hover:bg-green-600 mr-2"
                onClick={() => {
                  callbackActionFunction(inputEvent);
                }}
                disabled={inputEvent?.target.length < 1}
              >
                {actionName}
              </button>
              <button
                className="bg-gray-200 text-gray-800 px-4 py-2 rounded shadow-sm hover:bg-gray-300"
                onClick={callBackCancelFunction}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default AddLobbyModal;
