import React from 'react'
import { useNavigate } from 'react-router-dom';


function Page404() {
  const navigate = useNavigate();
    
  return (
    <div className="min-w-full py-52 min-h-screen justify-center items-center flex flex-col">
      <div className="flex flex-col items-center justify-center">
        <img src={"/images/the404.gif"} alt="404image"></img>
        <span className="font-bold mt-4">don't worry press the button to go back</span>
        <button className="main-button mt-4" onClick={() => navigate("/")}>Go back</button>
      </div>
      </div>
  )
}

export default Page404;