import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { socket } from "../../socket";
import EntityCard from "../EntityCard/EntityCard";
import ActionConfirmationModal from "../Modals/ActionConfirmationModal";
import ChooseEntitiesModal from "../Modals/ChooseEntitiesModal";
import EntitiesManagerModal from "../Modals/EntitiesManagerModal";

function DmSideManager({
  setIsGridShowed,
  setSelectedEntity,
  setIsClearing,
  selectedEntity,
  table,
  setDeletedEntity,
  deletedEntity,
  movingEntity,
}) {
  const USER = JSON.parse(localStorage.getItem("user"));
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [isOpenManager, setIsOpenManager] = useState(false);
  const [dm, setDm] = useState({});
  const [trigger, setTrigger] = useState(false);
  const navigate = useNavigate();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalAction, setModalAction] = useState("");
  const [callbackAction, setCallbackAction] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const TABLE_ID = localStorage.getItem("table_id");
  if (!TABLE_ID) {
    navigate("/login");
  }

  useEffect(() => {
    fetch("api/table/" + TABLE_ID + "/team/full", {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDm(data.team[0]);
      })
      .catch((err) => {
        if (err.message === "TNV") {
          // If the token is not valid, redirect to the login page
          navigate("/login");
        }
      });
  }, [TABLE_ID, navigate, trigger]);

  function clearGrid() {
    setModalTitle("Clear grid");
    setModalDescription("Are you sure you want to clear the grid?");
    setModalAction("Clear grid");
    setCallbackAction(() => () => {
      setIsClearing(true);
      setOpenConfirmationModal(false);
    });
    setOpenConfirmationModal(true);
  }

  function EndSession() {
    setModalTitle("End session");
    setModalDescription("Are you sure you want to end the session?");
    setModalAction("End session");
    setCallbackAction(() => () => {
      setOpenConfirmationModal(false);
      socket.emit("endSession", {
        table_id: TABLE_ID,
        user_id: USER,
      });
    });
    setOpenConfirmationModal(true);
  }

  function handleField(field, which) {
    let entity = selectedEntity || movingEntity;
    if (entity === null) return;
    let newMeta = entity?.meta;
    if (which === "hp") {
      // Set the value for the selected entityt meta field
      let index = newMeta.findIndex((item) => item.key.toUpperCase() === "HP");
      if (index === -1) {
        newMeta.push({ key: "HP", value: field });
      } else {
        newMeta[index].value = field;
      }
      setSelectedEntity({ ...entity, meta: newMeta });
    } else if (which === "init") {
      let index = newMeta.findIndex(
        (item) => item.key.toUpperCase() === "INITIATIVE"
      );
      if (index === -1) {
        newMeta.push({ key: "INITIATIVE", value: field });
      } else {
        newMeta[index].value = field;
      }
      setSelectedEntity({ ...entity, meta: newMeta });
    } else {
      let index = newMeta.findIndex(
        (item) => item.key.toUpperCase() === "MOVEMENT"
      );
      if (index === -1) {
        newMeta.push({ key: "MOVEMENT", value: field });
      } else {
        newMeta[index].value = field;
      }
      setSelectedEntity({ ...entity, meta: newMeta });
    }
  }
  return (
    <div id="EntityList" className="max-h-screen overflow-auto">
      <div>
        <div className="grid grid-cols-2 gap-4 my-4">
          <button className="main-button" onClick={() => EndSession()}>
            End session
          </button>
          <button
            className="main-button"
            onClick={() => {
              clearGrid();
            }}
          >
            Clear grid
          </button>
          {dm.entities?.length > 0 && (
            <button
              id="btn-manage"
              className="main-button"
              onClick={() => setIsOpenManager(true)}
            >
              Open Entities Manager
            </button>
          )}
          <button
            className="main-button"
            onClick={() => {
              setIsOpenImport(true);
            }}
          >
            Import entities
          </button>
        </div>

        {openConfirmationModal && (
          <ActionConfirmationModal
            callbackActionFunction={callbackAction}
            callbackCancelFunction={() => setOpenConfirmationModal(false)}
            modalTitle={modalTitle}
            modalAction={modalAction}
            modalDescription={modalDescription}
            isLoading={isLoading}
          />
        )}
      </div>
      <div
        id="entityListContainer"
        className="max-h-screen overflow-auto grid grid-cols-2"
      >
        {dm.entities?.map((entity, index) =>
          // If the entity has the TEMP flag, don't show it
          entity.meta.find((item) => item.key === "TEMP") ? null : (
            <EntityCard
              entity={entity}
              entityIndex={uuidv4()}
              key={uuidv4()}
              isEditable={true}
              managerView={false}
              setIsEditing={false}
              setSelectedEntity={setSelectedEntity}
              importView={false}
              entityOwner={dm.user_id}
              selectedEntity={selectedEntity}
              table={table}
              movingEntity={movingEntity}
            />
          )
        )}
      </div>
      <div className="grid grid-cols-2 gap-2">
        <label htmlFor="input-HP">HP</label>
        <input
          id="input-HP"
          className="text-black"
          onChange={(e) => handleField(e.target.value, "hp")}
          value={
            selectedEntity?.meta?.find(
              (item) => item.key.toUpperCase() === "HP"
            )?.value ||
            movingEntity?.meta?.find((item) => item.key.toUpperCase() === "HP")
              ?.value ||
            ""
          }
        ></input>
        <label htmlFor="input-INITIATIVE">INITIATIVE</label>
        <input
          id="input-INITIATIVE"
          className="text-black"
          onChange={(e) => handleField(e.target.value, "init")}
          value={
            selectedEntity?.meta?.find(
              (item) => item.key.toUpperCase() === "INITIATIVE"
            )?.value ||
            movingEntity?.meta?.find(
              (item) => item.key.toUpperCase() === "INITIATIVE"
            )?.value ||
            ""
          }
        ></input>
        <label htmlFor="input-MOVEMENT">MOVEMENT</label>
        <input
          id="input-MOVEMENT"
          className="text-black"
          onChange={(e) => handleField(e.target.value, "move")}
          value={
            selectedEntity?.meta?.find(
              (item) => item.key.toUpperCase() === "MOVEMENT"
            )?.value ||
            movingEntity?.meta?.find(
              (item) => item.key.toUpperCase() === "MOVEMENT"
            )?.value ||
            ""
          }
        ></input>
      </div>
      <button
        className="main-button"
        onClick={() => {
          let entity = selectedEntity || movingEntity;
          if (entity === null) return;
          // Save the selected entity to the database
          fetch(
            "api/table/" +
              TABLE_ID +
              "/team/" +
              USER._id +
              "/entity/" +
              entity._id,
            {
              method: "PUT",
              body: JSON.stringify(entity),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              setTrigger(!trigger);
            })
            .catch((err) => {
              if (err.message === "TNV") {
                // If the token is not valid, redirect to the login page
                navigate("/login");
              }
            });
        }}
      >
        Save
      </button>
      {isOpenManager && (
        <EntitiesManagerModal
          modalTitle="Manage your DM entities"
          dm={dm}
          table_id={localStorage.getItem("table_id")}
          entities={dm.entities}
          callbackCloseFunction={() => {
            setIsOpenManager(false);
            setTrigger(!trigger);
          }}
          setDeletedEntity={setDeletedEntity}
          deletedEntity={deletedEntity}
          openConfirmationModal={setOpenConfirmationModal}
          openEntitiesManagerModal={setIsOpenManager}
          actionSetter={setModalAction}
          descriptionSetter={setModalDescription}
          titleSetter={setModalTitle}
          callbackSetter={setCallbackAction}
          setTrigger={setTrigger}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isOpenImport && (
        <ChooseEntitiesModal
          modalTitle="Choose the entities to add"
          player={dm}
          chooseOnlyOne={false}
          callbackCancelFunction={() => setIsOpenImport(false)}
          callbackImportFunction={(choosenEntities, player) => {
            setIsLoading(true);
            fetch(
              "api/table/" + TABLE_ID + "/team/" + player.user_id + "/entities",
              {
                method: "POST",
                body: JSON.stringify({ entities: choosenEntities }),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                setTrigger(!trigger);
                setIsOpenImport(false);
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                if (err.message === "TNV") {
                  // If the token is not valid, redirect to the login page
                  navigate("/login");
                }
              });
          }}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default DmSideManager;
